
import Button from "@material-ui/core/Button";
import { MenuItem, Select, Table, TableCell, TableContainer, TableHead, TableRow, TextField } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import * as commonFnc from '../common/commonFnc';
import { useState } from "react";
import { Accordion, AccordionDetails, AccordionSummary, Box } from "@mui/material";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const SP_ShiftTableHeader = (props) =>{
    const {
        isMobile
        , Shift_msts
        , Department_msts
        , serchCondition
        , setSerchCondition
        , getShiftTable
        , Login_user_info
        , isViewDept
        , setIsViewDept
        , isViewPaidHoliday
        , setIsViewPaidHoliday
        , TIME_PAID_HOLIDAY_FLG
        , setRows
        , sortKey
        , setSortKey
    } = props;
    
    const [deptgr_cd, setDeptgr_cd] = useState(serchCondition.target_deptgr_cd);
    const SHIFT_START_DAY = 1;

    const handleInputChage = (e) =>{
        const target = e.target;
        const value = target.value;
        const name = target.name;
        setSerchCondition({ ...serchCondition, [name]: value });
    } 

    const handleButtonClick = (p) =>{

        var d_from = new Date(document.getElementById("target_date_from").value);
        var d_to = new Date(document.getElementById("target_date_to").value);
        
        var addMonth_from = p;
        var addMonth_to = (SHIFT_START_DAY===1 ? p+1 : p)

        setSerchCondition({
            ...serchCondition,
            target_date_from : commonFnc.formatDate(new Date(d_from.getFullYear(), d_from.getMonth()+addMonth_from, SHIFT_START_DAY)),
            target_date_to : commonFnc.formatDate(new Date(d_to.getFullYear(), d_to.getMonth()+addMonth_to, SHIFT_START_DAY-1)),
        })
        
    };

    
    const handleOnClickSearchButton = () => {
        setRows(null);
        getShiftTable();
    };
    const handleOnClickClearButton = () => {
        setSerchCondition({
            ...serchCondition,
            target_deptgr_cd : "",
            target_dept_cd : "",
            target_user_id : "",
            target_user_name : "",
            target_shift_type_cd : "",
            exist_over_time : 0, //　残業ありなし両方
        })
    };

    
    const [expanded, setExpanded] = useState(false);

    const handleAccodionChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
      };

    const style={
        accodionSummary:{
            textAlign:'center'
        },
        searchBoxRow:{
            display:'flex',
            margin:'5px auto',
        },
        headerAcctionButton:{
            minWidth:'23%',
            margin:'0px auto',
            fontSize:'0.7rem',
        },
        searchBox:{
            minWidth:'30%',
            margin:'0px auto',
            fontSize:'0.8rem',
            textAlign:'center',
        },
        searchBoxDate:{
            width:'43%',
        },
        searchBoxButton:{
            width: '1.5rem',       // ボタンの幅を設定
            height: '40px',      // 必要に応じて高さも設定
            padding: '0 10px',        // 余白を調整
            margin: '0 5px',        // 余白を調整
            minWidth: 'unset',    // デフォルトのminWidthを上書き
        }
    }

    return (
        <Box>
            <Box sx={style.searchBoxRow}>
                <Button
                onClick={() => handleButtonClick(-1)}
                style={style.searchBoxButton}
                >
                ◁
                </Button>
                <div style={style.searchBoxDate}>
                <TextField 
                    label="日付from" 
                    id="target_date_from"  
                    name="target_date_from" 
                    type="Date" 
                    className="serchBox" 
                    value={serchCondition.target_date_from} 
                    inputProps={{style: style.searchBox}} 
                    onChange={(e)=>handleInputChage(e)} 
                    fullWidth
                />
                </div>
                {/* <div style={{margin:'0 2rem'}}>
                ～
                </div> */}
                <div style={style.searchBoxDate}>   
                <TextField 
                    label="日付to" 
                    id="target_date_to" 
                    name="target▷_date_to" 
                    type="Date" 
                    className="serchBox" 
                    value={serchCondition.target_date_to} 
                    inputProps={{style: style.searchBox}} 
                    onChange={(e)=>handleInputChage(e)}/>
                </div>
                <Button 
                    onClick={()=>handleButtonClick(1)} 
                    style={style.searchBoxButton}>
                    ▷
                </Button>

            </Box>
            <Box sx={style.searchBoxRow}>
                <Select 
                    label="施設" 
                    id="target_deptgr_cd" 
                    name="target_deptgr_cd" 
                    style={style.searchBox} 
                    value={serchCondition.target_deptgr_cd} 
                    onChange={(e)=>{handleInputChage(e); setDeptgr_cd(e.target.value)}}
                    disabled={Login_user_info.authority <= 30}>
                    {Department_msts.filter(item => item.department_type === 3).map((department_mst, index)=>(
                        <MenuItem key={index} value={department_mst.department_cd}>{department_mst.name}</MenuItem>
                    ))}
                </Select>
                <Select 
                    label="部署" 
                    id="target_dept_cd" 
                    name="target_dept_cd"
                    style={style.searchBox} 
                    value={serchCondition.target_dept_cd} 
                    onChange={(e)=>handleInputChage(e)}
                    disabled={Login_user_info.authority <= 10}>
                    {Department_msts.filter(item => item.department_type === 5 && item.highly_department_cd === deptgr_cd).map((department_mst, index)=>(
                        <MenuItem key={index} value={department_mst.department_cd}>{department_mst.name}</MenuItem>
                    ))}
                </Select>
                <Select
                    style={style.searchBox} 
                    onChange={e => setSortKey(e.target.value)}
                    defaultValue={sortKey||'user_id'}
                    name="csvSortType">
                    <MenuItem value="user_id">職員番号順</MenuItem>
                    <MenuItem value="csv_seq">CSV出力順</MenuItem>
                </Select>
            </Box>
            {/* <Accordion 
                slotProps={{ heading: { component: 'h4' } }} 
                >
                <AccordionSummary
                    sx={style.accodionSummary}
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    詳細
                </AccordionSummary>
                <AccordionDetails>
                    <Box sx={style.searchBoxRow}>
                        <TextField label="施設" id="target_deptgr_cd" name="target_deptgr_cd" select style={style.searchBox} value={serchCondition.target_deptgr_cd} onChange={(e)=>{handleInputChage(e); setDeptgr_cd(e.target.value)}}>
                            {Department_msts.filter(item => item.department_type === 3).map((department_mst, index)=>(
                                <MenuItem key={index} value={department_mst.department_cd}>{department_mst.name}</MenuItem>
                            ))}
                        </TextField>
                        <TextField label="部署" id="target_dept_cd" name="target_dept_cd" select style={style.searchBox} value={serchCondition.target_dept_cd} onChange={(e)=>handleInputChage(e)}>
                            {Department_msts.filter(item => item.department_type === 5 && item.highly_department_cd === deptgr_cd).map((department_mst, index)=>(
                                <MenuItem key={index} value={department_mst.department_cd}>{department_mst.name}</MenuItem>
                            ))}
                        </TextField>
                    </Box>
                </AccordionDetails>
            </Accordion> */}
            <Box sx={style.searchBoxRow}>
                <Button style={style.headerAcctionButton} variant="outlined" onClick={() => setIsViewDept(!isViewDept)}>部署表示</Button>
                {TIME_PAID_HOLIDAY_FLG?
                    <Button style={style.headerAcctionButton} variant="outlined" onClick={() => setIsViewPaidHoliday(!isViewPaidHoliday)}>時間有給</Button>:<></>
                }
                <Button style={style.headerAcctionButton} variant="outlined" onClick={() => handleOnClickClearButton()}>クリア</Button>
                <Button style={style.headerAcctionButton} variant="outlined" onClick={() => handleOnClickSearchButton()}>検索</Button>
            </Box>    
        </Box>
    )
}
export default SP_ShiftTableHeader;