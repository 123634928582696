import { Accordion, AccordionDetails, AccordionSummary, Box, Button, makeStyles, Tooltip, Typography, useMediaQuery } from "@material-ui/core";
import { useEffect, useState, useContext } from 'react';
import { useHistory } from "react-router-dom";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import CommonDialog from "../common/CommonDialog";
import * as commonFnc from '../common/commonFnc';
import * as BL_requestKintai from '../BL/BL_requestKintai_tmp';
import {GlobalContext} from '../globalState'
import { Kintai_overtime } from "../classes/Kintai_overtime_class";
import { FormControl, Select, MenuItem, useTheme, TextField, Divider } from "@mui/material";
import * as Kintai_bsc_class from "../classes/kintai_bscs_class";
import ArrowRight from '@material-ui/icons/ArrowRight';
import ArrowLeft from '@material-ui/icons/ArrowLeft';



const RequestKintaiForm = (props) =>{

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));


    const useStyles = makeStyles((theme)=>(
        {
            root:!isMobile?{
                '& .MuiTextField-root':{
                    margin: theme.spacing(1),
                    width: 250,
                }
            }
            :
            {
                '& .MuiTextField-root':{
                    margin: '3px 2px',
                    width: 90,
                    fontSize:'0.7rem',
                },
                '.MuiFormControlLabel-label': {
                    fontSize:'0.7rem',
                },
            }
        }
    ))
    
    const styles = {
        formRow:{
            display :'flex',
            margin:'10px 0px',
            flexWrap: 'wrap',
        },
        textArea:{
            display:'table',
        },
        formText:{
            color:'gray',
            fontSize:'1.7rem',
            fontWeight:'bold',
            padding: '0px 50px 0px 0px ',
            verticalAlign: 'middle',
            display:'table-cell'
        },
        formButton:{
            width:'100%',
            margin:"0px 7px"
        }
    }


    // 01_props
    const history = useHistory();
    const classes = useStyles();
    const {
        login_user_id, 
        Shift_msts, 
        setKintai_bscs, 
        Kintai_bsc, 
        setKintai_bsc, 
        Request_overtimes,
        setRequest_overtimes, 
        Kintai_outtime, 
        setKintai_outtime, 
        Request_paid_holiday,
        setRequest_paid_holiday, 
        Login_user_info, 
        setLogin_user_info, 
        display_user_info,
        // setDisplay_user_info,
        Department_msts,
        triggerReloadData,
        setTriggerReloadData,
    } = props;
    
    // 02_useState
    const shift_group_cd_hash = useContext(GlobalContext).shift_group_cd;
    const Env_msts = useContext(GlobalContext).Env_msts;
    const approvalTexts = useContext(GlobalContext).approvalTexts;
    
    const IS_ENABLE_REQUEST_KINTAI = Env_msts.find(p => p.env_key === "IS_ENABLE_REQUEST_KINTAI")?.env_bool;
    const IS_ENABLE_NEGATIVE_PUBLIC_HOLIDAY_REQUEST = Env_msts.find(p => p.env_key === "IS_ENABLE_NEGATIVE_PUBLIC_HOLIDAY_REQUEST")?.env_bool;
    const IS_ENABLE_NEGATIVE_PAID_HOLIDAY_REQUEST = Env_msts.find(p => p.env_key === "IS_ENABLE_NEGATIVE_PAID_HOLIDAY_REQUEST")?.env_bool;
    const IS_ENABLE_SHIFT_CHANGE_CHECK = Env_msts.find(p => p.env_key === "IS_ENABLE_SHIFT_CHANGE_CHECK")?.env_bool;

    const department_mst = Department_msts.find(p=>p.department_cd === display_user_info.department_cd)
    

    const [deleteDlgisOpen, setDeletemnDlgisOpen] = useState(false);

    const [errmsg, setErrmsg] = useState("");
    // const [isErrNotEqualTotalTimeAndRequestTime, setIsErrNotEqualTotalTimeAndRequestTime] = useState(true);

    const [before_enable_public_holiday_time, setBefore_enable_public_holiday_time] = useState(Kintai_bsc.public_holiday_time);
    const [before_enable_paid_holiday_time, setBefore_enable_paid_holiday_time] = useState(Kintai_bsc.paid_holiday_time);
    
    // シフト変更時に有休公休時間計算に使用
    const [before_shift_type_cd, setBefore_shift_type_cd] = useState(Kintai_bsc.shift_type_cd);
    const [before_harf_holiday_shift_type_cd, setBefore_harf_holiday_shift_type_cd] = useState(Kintai_bsc.harf_holiday_shift_type_cd);
    const [before_paid_holiday_shift_type_cd, setBefore_paid_holiday_shift_type_cd] = useState(Kintai_bsc.paid_holiday_shift_type_cd);

    // 
    const [before_submit_shift_type_cd, setBefore_submit_shift_type_cd] = useState(Kintai_bsc.shift_type_cd);

    const [is_change_shift, setIs_change_shift] = useState(false);

    const [is_below_enable_public_holiday_days, setIs_below_enable_public_holiday_days] = useState(false);
    // const [is_below_enable_paid_holiday_days, setIs_below_enable_peid_holiday_days] = useState(false);

    // const [isDisable_work_time                   ,setIsDisable_work_time] = useState(false);
	// const [isDisable_rest_time                   ,setIsDisable_rest_time] = useState(false);
	// const [isDisable_over_time                   ,setIsDisable_over_time] = useState(false);
	// const [isDisable_midnight_over_time         ,setIsDisable_midnight_over_time] = useState(false);
	// const [isDisable_behind_time                 ,setIsDisable_behind_time] = useState(false);
	// const [isDisable_early_time                  ,setIsDisable_early_time] = useState(false);
	// const [isDisable_out_time                    ,setIsDisable_out_time] = useState(false);
	// const [isDisable_training_time               ,setIsDisable_training_time] = useState(false);
	// const [isDisable_over_time_from              ,setIsDisable_over_time_from] = useState(false);
	// const [isDisable_over_time_to                ,setIsDisable_over_time_to] = useState(false);
	// const [isDisable_reason                      ,setIsDisable_reason] = useState(false);
	// const [isDisable_out_time_from               ,setIsDisable_out_time_from] = useState(false);
	// const [isDisable_out_time_to                 ,setIsDisable_out_time_to] = useState(false);
	const [isDisable_request_paid_holiday_reason ,setIsDisable_request_paid_holiday_reason] = useState(false);
	const [isDisable_confirm_button ,setIsDisable_confirm_button] = useState(false);
	const [isDisable_delete_button ,setIsDisable_delete_button] = useState(false);
	const [isDisable_shiftConfirm_button ,setIsDisable_shiftConfirm_button] = useState(false);

    

	const [isHidden_addShift,setIsHidden_addShift] = useState(true);
	const [isHidden_addPaidShift,setIsHidden_addPaidShift] = useState(true);

    const [isExpanded_request_over_time, setIsExpanded_request_over_time] = useState(true);

    let enable_time_paid_holiday = Env_msts.find(p => p.env_key === "TIME_PAID_HOLIDAY")?.env_bool;
    const UNIT_WORK_TIME_TYPE = Env_msts.find(p => p.env_key === "UNIT_WORK_TIME_TYPE")?.env_value;
    const [count1State, setCount1State] = useState({});
    const [count2State, setCount2State] = useState({});
    const [count3State, setCount3State] = useState({});
    const [count4State, setCount4State] = useState({});
    const [count5State, setCount5State] = useState({});
    const tmp_count1 = Env_msts.find(p => p.env_key === "COUNT1");
    const tmp_count2 = Env_msts.find(p => p.env_key === "COUNT2");
    const tmp_count3 = Env_msts.find(p => p.env_key === "COUNT3");
    const tmp_count4 = Env_msts.find(p => p.env_key === "COUNT4");
    const tmp_count5 = Env_msts.find(p => p.env_key === "COUNT5");

    useEffect(()=>{
        // 画面更新等でログインユーザinfoが取得出来ない場合は前の画面に戻る
        if(!Login_user_info  || !display_user_info){
            history.goBack();
        }
        
    },[])
    
    useEffect(()=>{
      setCount1State({
          name:tmp_count1?.env_value,
          isHidden:tmp_count1?.log_del_flg,
          isChecked:false,
      });
      setCount2State({ 
          name:tmp_count2?.env_value,
          isHidden:tmp_count2?.log_del_flg,
          isChecked:false,
      });
      setCount3State({
          name:tmp_count3?.env_value,
          isHidden:tmp_count3?.log_del_flg,
          isChecked:false,
      });
      setCount4State({
          name:tmp_count4?.env_value,
          isHidden:tmp_count4?.log_del_flg,
          isChecked:false,
      });
      setCount5State({
          name:tmp_count5?.env_value,
          isHidden:tmp_count5?.log_del_flg,
          isChecked:false,
      });
      enable_time_paid_holiday = Env_msts.find(p => p.env_key === "TIME_PAID_HOLIDAY")?.env_bool
    },[Env_msts]);

    const isCounterNoUse = count1State.isHidden && count2State.isHidden && count3State.isHidden && count4State.isHidden && count5State.isHidden;

    if(Kintai_bsc.approval_status_cd === null || Kintai_bsc.approval_status_cd === undefined || Kintai_bsc.approval_status_cd.length === 0){
        Kintai_bsc.approval_status_cd = "10";
    }

    // 打刻ジャーナル取得
    const [Clockin_time_from, setClockin_time_from] = useState();
    const [Clockin_time_to, setClockin_time_to] = useState();
    
    // 03_独自関数(eventHandlerなど)

    useEffect(()=>{
        
        if(!Kintai_bsc.date){
            const mykintai_bsc = JSON.parse(localStorage.getItem('mykintai_bsc'));
            setKintai_bsc(mykintai_bsc);
        }
    },[])

    useEffect(()=>{

        if(Kintai_bsc?.harf_holiday_shift_type_cd) setIsHidden_addShift(false);
        if(Kintai_bsc?.paid_holiday_shift_type_cd) setIsHidden_addPaidShift(false);

        // 申請前、差戻、かつ、
        // 環境変数:IS_ENABLE_REQUEST_KINTAIがTRUE　 →　誰でも登録ボタンを押せる。
        // 環境変数:IS_ENABLE_REQUEST_KINTAIがFALSE　→　本人であれば登録ボタンを押せる。
        // if((Kintai_bsc.approval_status_cd === "10" || Kintai_bsc.approval_status_cd === "20" || Kintai_bsc.approval_status_cd === "80")
        if((Kintai_bsc.approval_status_cd === "10" || Kintai_bsc.approval_status_cd === "80")
         && (IS_ENABLE_REQUEST_KINTAI ? true : Login_user_info.user_id === display_user_info.user_id)
        ){

            setIsDisable_confirm_button(false);
            setIsDisable_delete_button(false);
            setIsDisable_shiftConfirm_button(false);
        }else{
            setIsDisable_confirm_button(true);
            setIsDisable_delete_button(true);
            setIsDisable_shiftConfirm_button(true);
        }
        
        const query = new URLSearchParams({shift_date: commonFnc.formatDate(Kintai_bsc.date), user_id: display_user_info.user_id});
        fetch(`/api/select/clockin_jarnal?${query}`)
        .then((res) => res.json())
        .then((data) => {
            setClockin_time_from(data.sort((a,b)=>b.createdAt - a.createdAt).filter((p)=>p.clockin_type_cd === "10")[0])
            setClockin_time_to(data.sort((a,b)=>b.createdAt - a.createdAt).find((p)=>p.clockin_type_cd === "90"))
        })
    },[Kintai_bsc]);
    
    useEffect(()=>{
        const errmsg = regularValidate("");
        setErrmsg(errmsg)
        
    },[Kintai_bsc,Request_paid_holiday]);

    useEffect(()=>{
        // 残業が存在すればオープン
        // setIsExpanded_request_over_time(Request_overtimes[0]?.over_time_from || Request_overtimes[0]?.over_time_to || Request_overtimes[0]?.reason);

        const errmsg = regularValidate("");
        setErrmsg(errmsg)
        
    },[Request_overtimes]);
    
    const handleInputChage = (e) =>{
        const target = e.target;
        const value = target.value;
        const name = target.name;

        if(name === "out_time_from" || name === "out_time_to"){
            setKintai_outtime({ ...Kintai_outtime, [name]: value });
        }else if(name === "request_paid_holiday_reason"){
            setRequest_paid_holiday({ ...Request_paid_holiday, [name]: value });
        }else{
            setKintai_bsc({ ...Kintai_bsc, [name]: value });
        }
    }

    // 残業申請等のListのstateの変更はこちらを使用
    const handleInputChageList = (e, setObjList, ObjList, index) =>{
        
        var Obj = ObjList[index];

        const target = e.target;
        const value = target.value;
        const name = target.name;

        Obj[name] = value;
        ObjList[index]=Obj;
        const list = ObjList.map((p)=>{ return p}); // ディープコピー
        
        setObjList(list);
    }

    const handleCheckedChange = (e) => {
        const target = e.target;
        const checked = target.checked;
        const name = target.name;
        setKintai_bsc({ ...Kintai_bsc, [name]: checked });
    };
    
    const handleInputChageConvertHHmmToMsec = (e) =>{
        const target = e.target;
        const value = commonFnc.getMsec(target.value);
        const name = target.name;
        setKintai_bsc({ ...Kintai_bsc, [name]: value });
    } 

    /**
     * シフト変更
     * @param {*} e イベント
     * @param {*} beforeShiftTypeCd 変更前のシフトタイプCd
     * @param {*} setBeforeShiftTypecd 変更前のシフトタイプCdのセッター
     */
    const handleSelectShiftType = (e, beforeShiftTypeCd, setBeforeShiftTypecd) =>{

        const target = e.target;
        const value = target.value;
        const name = target.name;

        const afterShiftTypeCd = value;
        
        // 有給公休　加減算
        const reducePublicHolidayTime = BL_requestKintai.getReduceHolidayTime(
                                                    Shift_msts, 
                                                    beforeShiftTypeCd, 
                                                    afterShiftTypeCd, 
                                                    "public_holiday_time",
                                                );
        const reducePaidHolidayTime = BL_requestKintai.getReduceHolidayTime(
                                                    Shift_msts, 
                                                    beforeShiftTypeCd, 
                                                    afterShiftTypeCd, 
                                                    "paid_holiday_time",
                                                );
        // const reducePublicHolidayDays = reducePublicHolidayTime/8;
        // const reducePaidHolidayDays = reducePaidHolidayTime/8;
        

        // 有給公休の消費時間数を取得
        const publicHolidayTime = (Kintai_bsc.public_holiday_time?Kintai_bsc.public_holiday_time:0) + reducePublicHolidayTime;
        const paidHolidayTime = (Kintai_bsc.paid_holiday_time?Kintai_bsc.paid_holiday_time:0) + reducePaidHolidayTime;


        // 表示上の残数を更新:DB書き込みは登録時　※Display_user_infoの方が良いか？
        // setLogin_user_info({
        //     ...Login_user_info,
        //     enable_public_holiday_days:Login_user_info.enable_public_holiday_days - reducePublicHolidayDays,
        //     enable_paid_holiday_days:Login_user_info.enable_paid_holiday_days - reducePaidHolidayDays,
        // });

        // 半日シフトなら半日休暇を表示
        if(name === "shift_type_cd"){
            const after_shift = Shift_msts.find((p)=>p.shift_type_cd === afterShiftTypeCd);
            if(after_shift?.shift_group_cd === shift_group_cd_hash["HARF_WORK_SHIFT_GROUP_CD"] 
                || after_shift?.shift_group_cd === shift_group_cd_hash["HARF_OVER_NIGHT_WATCH_SHIFT_GROUP_CD"]){
                setIsHidden_addShift(false)
            }else{
                // 半日休暇が設定されていない場合は隠す
                if(!Kintai_bsc.harf_holiday_shift_type_cd){
                    setIsHidden_addShift(true   )
                }

                // 半休以外のシフト選択時には自動で半日休暇を削除したいが、上手く行かない。
                // deleteAddShift内と274行目でsetKintai_bscが重複している事が原因。

                // deleteAddshift(
                //     Kintai_bsc.harf_holiday_shift_type_cd,
                //     "harf_holiday_shift_type_cd",
                //     "public_holiday_time",
                //     "enable_public_holiday_days", 
                //     setIsHidden_addShift, 
                //     setBefore_harf_holiday_shift_type_cd
                // )
                
            }
        }

        // 前回値更新
        setBeforeShiftTypecd(afterShiftTypeCd);

        // シフト変更フラグ更新
        setIs_change_shift(true);

        Kintai_bsc[name]=afterShiftTypeCd;
        const tmp_Kintai_bsc = BL_requestKintai.changeWorkTime("work_time_to", Kintai_bsc.work_time_to, Kintai_bsc, Shift_msts, shift_group_cd_hash, paidHolidayTime, UNIT_WORK_TIME_TYPE)
        
        // 勤怠情報更新
        setKintai_bsc({
            ...tmp_Kintai_bsc,
            [name]: afterShiftTypeCd,
            public_holiday_time: publicHolidayTime,
            paid_holiday_time: paidHolidayTime,
        });

    }

    // シフト時刻反映
    const handleShiftConfirmButton = () =>{

        if(!Kintai_bsc.shift_type_cd) {
            alert("シフトが選択されていません。")
            return;
        }

        const target_shift = Shift_msts.find((p)=>p.shift_type_cd === Kintai_bsc.shift_type_cd);

        if(!target_shift.shift_time_from || !target_shift.shift_time_to){
            alert("時刻が設定されていないシフトです。")
            return;
        }

        let tmp_day_from = new Date(Kintai_bsc.date);
        tmp_day_from.setHours(target_shift.shift_time_from.split(":")[0])
        tmp_day_from.setMinutes(target_shift.shift_time_from.split(":")[1])


        // 夜勤・宿直系なら翌日の時刻として設定
        let tmp_day_to = new Date(Kintai_bsc.date);
        if( target_shift.shift_group_cd === shift_group_cd_hash["NIGHT_SHIFT_GROUP_CD"] 
            || target_shift.shift_group_cd === shift_group_cd_hash["NIGHT_WATCH_SHIFT_GROUP_CD"] 
            || target_shift.shift_group_cd === shift_group_cd_hash["OVER_NIGHT_WATCH_SHIFT_GROUP_CD"] 
            || target_shift.shift_group_cd === shift_group_cd_hash["HARF_OVER_NIGHT_WATCH_SHIFT_GROUP_CD"] ){
            tmp_day_to.setDate(tmp_day_to.getDate() + 1);
        }

        tmp_day_to.setHours(target_shift.shift_time_to.split(":")[0])
        tmp_day_to.setMinutes(target_shift.shift_time_to.split(":")[1])

        // changeWorkTimeにtoしか渡せないため事前にfromを格納
       Kintai_bsc.work_time_from = tmp_day_from;
       setKintai_bsc(BL_requestKintai.changeWorkTime("work_time_to", tmp_day_to, Kintai_bsc, Shift_msts, shift_group_cd_hash, Kintai_bsc.paid_holiday_time, UNIT_WORK_TIME_TYPE))
    }

    // 勤務時間を再計算する。
    const handleReCalcWorkTikme = () =>{

        const result = window.confirm('勤務時間を再計算しますか？');
        if(result){
            setKintai_bsc(BL_requestKintai.changeWorkTime('work_time_from', Kintai_bsc.work_time_from, Kintai_bsc, Shift_msts ,shift_group_cd_hash, Kintai_bsc.paid_holiday_time, UNIT_WORK_TIME_TYPE))
        }

    }

    // const handleTimeInputButtonClick = (name) =>{   

    //     setKintai_bsc({
    //         ...Kintai_bsc,
    //         [name]: commonFnc.formatDateTime(new Date())
    //    });
    // }

    // 休憩時間を勤務時間に加算する。
    const handleAjastRestTime = () => {
        if(Kintai_bsc.rest_time !== 0){
            const result = window.confirm("休憩時間を勤務時間に加算しますか？");
            if(!result) return ;
            setKintai_bsc({
                ...Kintai_bsc,
                work_time:Kintai_bsc.work_time + Kintai_bsc.rest_time,
                rest_time:0,
            });

        }else{
            const result = window.confirm("シフトに登録された休憩時間を勤務時間から減算しますか？");
            if(!result) return ;

            const shift = Shift_msts.find(p=>p.shift_type_cd === Kintai_bsc.shift_type_cd);
            if(!shift) {
                alert("存在しないシフトです。");
                return ;
            }

            setKintai_bsc({
                ...Kintai_bsc,
                work_time:Kintai_bsc.work_time - shift.rest_time,
                rest_time:shift.rest_time,
            });
        }

    }

    /**
     * 追加休暇シフト取消ボタン
     * @param {string} shift_type_cd 削除対象のシフトタイプCd
     * @param {string} shiftCdName 削除対象のシフト項目名 半日休暇：harf_holiday_shift_cd or 時間有給：paid_holiday_shift_cd
     * @param {Obj} setHiddenStateObj セッター
     * @param {Obj} setBeforeStateObj セッター
     */
    const handleDelshiftButtonClick = (shift_type_cd, shiftCdName, setHiddenStateObj, setBeforeStateObj) =>{

        deleteAddshift(shift_type_cd, shiftCdName, setHiddenStateObj, setBeforeStateObj);
        // シフト変更フラグ更新
        setIs_change_shift(true);
    }
    
    /**
     * 追加休暇シフト取消処理
     * @param {string} shift_type_cd 削除対象のシフトタイプCd
     * @param {string} shiftCdName 削除対象のシフト項目名 半日休暇：harf_holiday_shift_cd or 時間有給：paid_holiday_shift_cd
     * @param {Obj} setHiddenStateObj セッター
     * @param {Obj} setBeforeStateObj セッター
     */
    const deleteAddshift = (shift_type_cd, shiftCdName, setHiddenStateObj, setBeforeStateObj) =>{

        if(shift_type_cd){
            var result = window.confirm('シフトが登録されています。取り消しますか？');
            if( !result ) return;
        }

        const tmp_harfShift = Shift_msts.find((p)=>p.shift_type_cd === shift_type_cd);
        const publicHolidayTime = tmp_harfShift&&tmp_harfShift.public_holiday_time?tmp_harfShift.public_holiday_time:0;
        const paidHolidayTime = tmp_harfShift&&tmp_harfShift.paid_holiday_time?tmp_harfShift.paid_holiday_time:0;

        // 表示上の残数を更新:DB書き込みは登録時
        // setLogin_user_info({
        //     ...Login_user_info,
        //     enable_public_holiday_days:display_user_info.enable_public_holiday_days + publicHolidayTime/8,
        //     enable_paid_holiday_days:display_user_info.enable_paid_holiday_days + paidHolidayTime/8,
        // });

        setKintai_bsc({
            ...Kintai_bsc,
            [shiftCdName]: "",
            public_holiday_time: Kintai_bsc.public_holiday_time - publicHolidayTime,
            paid_holiday_time: Kintai_bsc.paid_holiday_time - paidHolidayTime,
        });
        setHiddenStateObj(true);
        setBeforeStateObj(null);
    }

    // 時刻変更
    const handleChangeWorkTime = (e) =>{
        const target = e.target;
        const name = target.name;
        var day = Kintai_bsc[name] ? commonFnc.formatDate(Kintai_bsc[name]) : commonFnc.formatDate(Kintai_bsc.date);
        var value = new Date(day + " " + target.value);
        
        setKintai_bsc(BL_requestKintai.changeWorkTime(name, value, Kintai_bsc, Shift_msts ,shift_group_cd_hash, Kintai_bsc.paid_holiday_time, UNIT_WORK_TIME_TYPE))
    }
    
    // 削除ボタン
    const handleDeleteButtonClick = async (Kintai_bsc) =>{

        if(Kintai_bsc.user_id !== Login_user_info.user_id){
            const result = window.confirm(`${display_user_info.first_name}さんの勤怠です。\n削除しますか？`);
            if(!result) return;
        }

        setDeletemnDlgisOpen(false);

        const tmp_user_id = display_user_info.user_id;
        const tmp_shift = Shift_msts.find((p)=>p.shift_type_cd === Kintai_bsc.shift_type_cd);
        const tmp_harfShift = Shift_msts.find((p)=>p.shift_type_cd === Kintai_bsc.harf_holiday_shift_type_cd);
        const tmp_timePaidShift = Shift_msts.find((p)=>p.shift_type_cd === Kintai_bsc.paid_holiday_shift_type_cd);
        
        let publicHolidayTime = (tmp_shift&&tmp_shift.public_holiday_time?tmp_shift.public_holiday_time:0)
                                     + (tmp_harfShift&&tmp_harfShift.public_holiday_time?tmp_harfShift.public_holiday_time:0);
        let paidHolidayTime = (tmp_shift&&tmp_shift.paid_holiday_time?tmp_shift.paid_holiday_time:0)
                                     + (tmp_harfShift&&tmp_harfShift.paid_holiday_time?tmp_harfShift.paid_holiday_time:0)
                                     + (tmp_timePaidShift&&tmp_timePaidShift.paid_holiday_time?tmp_timePaidShift.paid_holiday_time:0);

        try{
            // 勤怠関連情報削除処理
            var url = "/api/deleteOne/request_kintai_bsc"
            var bodyData = {
                user_id:tmp_user_id,
                date: commonFnc.formatDate(Kintai_bsc.date),
                enable_paid_holiday_days:Login_user_info.enable_paid_holiday_days + paidHolidayTime/8,
                enable_public_holiday_days:Login_user_info.enable_public_holiday_days + publicHolidayTime/8,
            }
            const requestOptions ={
                method: 'POST',
                headers:{'Accept': 'application/json','Content-Type': 'application/json'},
                body: JSON.stringify(bodyData)
            };

            const response = await fetch(url,requestOptions);
            commonFnc.insertUserLog("INFO", tmp_user_id, __filename, "勤怠申請画面", "勤怠削除処理", "削除処理", "", null, bodyData);


            if(response.ok){

                // ユーザ情報更新
                const queryGetLoginUser = new URLSearchParams({user_id: login_user_id});
                const resGetLoginUser = await fetch(`/api/select/login_user?${queryGetLoginUser}`);
                const dataGetLoginUser = await resGetLoginUser.json();
                setLogin_user_info(dataGetLoginUser);
                
                // 勤怠情報再取得
                const queryKintai_bscs = new URLSearchParams({login_user_id: login_user_id});
                const res = await fetch(`/api/select/kintai_bsc?${queryKintai_bscs}`);
                const data = await res.json();
                setKintai_bscs(data);
    
                // ログ出力
                commonFnc.insertUserLog("INFO", tmp_user_id, __filename, "勤怠申請画面", "勤怠削除処理", "削除処理", "", null, Kintai_bsc);
    
                // 戻る
                history.goBack()
            }else{
                // エラーレスポンスを受け取った場合
                const data = await response.json();
                // エラーが発生した場合にユーザにアラートを表示する
                alert(data.error);
            }
        }catch(error){
            // リクエスト自体が失敗した場合
            // ユーザにアラートを表示する
            alert('通信エラーが発生しました');
        }
    }

    // 確定ボタン
    const handleConfirmOverTimeButtonClick = (Request_overtime, index) =>{
        // console.log('------------------確定------------------')

        if(Request_overtime.approval_status_cd !== "10") {
            alert("確定済みの申請です。")
            return;
        }

        if(!Request_overtime || !Request_overtime.over_time_from || !Request_overtime.over_time_to) {
            alert("入力されていない残業時間があります。")
            return;
        }

        if(Request_overtime.over_time_from === Request_overtime.over_time_to) {
            alert("開始終了に同じ時刻が設定されています。")
            return;
        }


        var overtimes = BL_requestKintai.calcOverHours(Kintai_bsc, Request_overtime.over_time_from, Request_overtime.over_time_to, Kintai_bsc.date, shift_group_cd_hash);
        var overtime = overtimes[0];
        var midnight_over_time = overtimes[1];
        var trainingtime = Kintai_bsc.training_time ? Kintai_bsc.training_time : 0;
        // console.log(overtimes);

        if(BL_requestKintai.isFromtimeOverTotime(Request_overtime.over_time_from.getTime(), Request_overtime.over_time_to.getTime())){
            alert("終了時刻は開始時刻より後ろの時刻を設定してください。")
            return;
        }
        if(trainingtime < overtime+midnight_over_time){
            alert("勤務時間の範囲内で残業申請をしてください。")
            return;
        }

        var Obj = Request_overtimes[index];
        Obj["approval_status_cd"] = "90";
        Request_overtimes[index]=Obj;
        const list = Request_overtimes.map((p)=>{ return p});
        setRequest_overtimes(list);
        

        // 時間有給時間シフト取得
        const timePaidHolidayShift = Kintai_bsc.paid_holiday_shift_type_cd?Shift_msts.find((p)=>p.shift_type_cd === Kintai_bsc.paid_holiday_shift_type_cd):null;
        const time_paid_holiday_time_msec = timePaidHolidayShift?(timePaidHolidayShift.paid_holiday_time?timePaidHolidayShift.paid_holiday_time:0) * 60 * 60 * 1000:0;
    
        // // 時間有給を取得している場合は残業時間を調整
        if(time_paid_holiday_time_msec){
            
            var addTime = 0;
            var worktime = Kintai_bsc.work_time;
            var totalOverTime = overtime + midnight_over_time;
            
            // メインシフト取得
            const Shift_mst = Shift_msts.find((p)=>p.shift_type_cd === Kintai_bsc.shift_type_cd);
            const tmpKintaiDate = new Date(Kintai_bsc.date);

            // 1:当日　2:翌日
            if(Shift_mst.shift_time_to_date_flg==='2'){
                tmpKintaiDate.setDate(new Date(tmpKintaiDate).getDate()+1);
            }
            const shiftMsecFrom = new Date(commonFnc.formatDate(Kintai_bsc.date) + " " + Shift_mst.shift_time_from).getTime();
            const shiftMsecTo = new Date(commonFnc.formatDate(tmpKintaiDate) + " " + Shift_mst.shift_time_to).getTime();
            const shiftTimeMsec = shiftMsecTo - shiftMsecFrom - Shift_mst.rest_time;
            // console.log('shiftMsecFrom : ',shiftMsecFrom)
            // console.log('shiftMsecTo : ',shiftMsecTo)
            // console.log('shiftTime : ',(shiftMsecTo - shiftMsecFrom)/3600000)
            // console.log('Shift_mst.rest_time : ',Shift_mst.rest_time/3600000)
            // console.log('Kintai_bsc.work_time : ',Kintai_bsc.work_time/3600000)
            // console.log('shiftTimeMsec : ',shiftTimeMsec/3600000)

            if(Kintai_bsc.work_time < shiftTimeMsec){
                addTime = Math.min(overtime + midnight_over_time, shiftTimeMsec - Kintai_bsc.work_time);
                worktime = Kintai_bsc.work_time + addTime;
                if(addTime < overtime){
                    // console.log('1------------------')
                    overtime = overtime - addTime;
                }else{
                    // console.log('2------------------')
                    addTime -= overtime; 
                    overtime = 0;
                    midnight_over_time = midnight_over_time - addTime;
                }
            }

            // console.log('Kintai_bsc.over_time : ',Kintai_bsc.over_time/3600000)
            // console.log('overtime : ',overtime/3600000)
            setKintai_bsc({
                ...Kintai_bsc,
                work_time: worktime,
                over_time: Kintai_bsc.over_time ? Kintai_bsc.over_time + overtime: overtime,
                midnight_over_time: Kintai_bsc.midnight_over_time ? Kintai_bsc.midnight_over_time + midnight_over_time : midnight_over_time,
                training_time: trainingtime - totalOverTime
            });

        }else{

            setKintai_bsc({
                ...Kintai_bsc,
                over_time: Kintai_bsc.over_time ? Kintai_bsc.over_time + overtime: overtime,
                midnight_over_time: Kintai_bsc.midnight_over_time ? Kintai_bsc.midnight_over_time + midnight_over_time : midnight_over_time,
                training_time: trainingtime - overtime - midnight_over_time
            });
        }


        
    }

    // クリアボタン
    const handleDeleteOverTimeButtonClick = (Request_overtime, index) =>{
        // console.log('------------------クリア------------------')
        
        if(!(window.confirm('入力された情報を取り消しますか？')) ) return;

        // 同条件で削除ボタンをdisableにしているがロジックでも制御
        if(!(Kintai_bsc.approval_status_cd === "10" || Kintai_bsc.approval_status_cd === "80")){
            console.log("do not delete")
            return;
        }

        if(!Request_overtime || Request_overtime.over_time_from === "" || Request_overtime.over_time_to === "") {
            
            deleteListItem(setRequest_overtimes, Request_overtimes, index);
            return;
        }

        if(Request_overtime.approval_status_cd !== "90") {
            // 確定済みの時間ではない場合は何もせずリストから削除
            deleteListItem(setRequest_overtimes, Request_overtimes, index);
            return;
        }

        var overtimes = BL_requestKintai.calcOverHours(Kintai_bsc, Request_overtime.over_time_from, Request_overtime.over_time_to, Kintai_bsc.date, shift_group_cd_hash);
        var overtime = overtimes[0];
        var midnight_over_time = overtimes[1];
        var trainingtime = Kintai_bsc.training_time ? Kintai_bsc.training_time : 0;



        // 時間有給時間シフト取得
        const timePaidHolidayShift = Kintai_bsc.paid_holiday_shift_type_cd?Shift_msts.find((p)=>p.shift_type_cd === Kintai_bsc.paid_holiday_shift_type_cd):null;
        const time_paid_holiday_time_msec = timePaidHolidayShift?(timePaidHolidayShift.paid_holiday_time?timePaidHolidayShift.paid_holiday_time:0) * 60 * 60 * 1000:0;

        // 時間有給を取得している場合は残業時間を調整
        if(time_paid_holiday_time_msec){

            var reduceTime = 0;
            var worktime = Kintai_bsc.work_time;
            var totalOverTime = overtime + midnight_over_time;

            // メインシフト取得
            const Shift_mst = Shift_msts.find((p)=>p.shift_type_cd === Kintai_bsc.shift_type_cd);
            const tmpKintaiDate = new Date(Kintai_bsc.date);
            // 1:当日　2:翌日
            if(Shift_mst.shift_time_to_date_flg==='2'){
                tmpKintaiDate.setDate(new Date(tmpKintaiDate).getDate()+1);
            }

            const shiftMsecFrom = new Date(commonFnc.formatDate(Kintai_bsc.date) + " " + Shift_mst.shift_time_from);
            const shiftMsecTo = new Date(commonFnc.formatDate(tmpKintaiDate) + " " + Shift_mst.shift_time_to);
            const shiftTimeMsec = shiftMsecTo - shiftMsecFrom - Shift_mst.rest_time;

            // if(shiftTimeMsec - Kintai_bsc.work_time < time_paid_holiday_time_msec){
                // reduceTime = Math.min(overtime + midnight_over_time, time_paid_holiday_time_msec - (shiftTimeMsec - Kintai_bsc.work_time));
                // console.log(reduceTime)
                // if(reduceTime < overtime){
                //     overtime = overtime - reduceTime;
                // }else{
                //     reduceTime -= overtime; 
                //     overtime = 0;
                //     midnight_over_time = midnight_over_time - reduceTime;
                //     reduceTime -= midnight_over_time; 
                // }
                // worktime = Kintai_bsc.work_time - reduceTime;

                // reduceは負の数字
                const reduceMidnightOverTime = Math.min(Kintai_bsc.midnight_over_time - midnight_over_time, 0);
                const reduceOverTime = Math.min(Kintai_bsc.over_time - overtime, 0);
                worktime = Kintai_bsc.work_time + reduceMidnightOverTime + reduceOverTime


            // }
            // console.log(trainingtime)
            // console.log(totalOverTime)
            // console.log('Kintai_bsc.over_time : ',Kintai_bsc.over_time/3600000)
            // console.log('overtime : ',overtime/3600000)
            // console.log('reduceOverTime : ',reduceOverTime/3600000)
            setKintai_bsc({
                ...Kintai_bsc,
                work_time: worktime,
                over_time: Kintai_bsc.over_time - (overtime + reduceOverTime),
                midnight_over_time: Kintai_bsc.midnight_over_time - (midnight_over_time + reduceMidnightOverTime),
                training_time: trainingtime + totalOverTime
            });

        }else{
            setKintai_bsc({
                ...Kintai_bsc,
                over_time: Kintai_bsc.over_time - overtime,
                midnight_over_time: Kintai_bsc.midnight_over_time - midnight_over_time,
                training_time: trainingtime + overtime + midnight_over_time
            });
        }


        deleteListItem(setRequest_overtimes, Request_overtimes, index);
        
    }

    /**
     * 【単体】当翌フラグを切り替える
     * @param {*} e 
     * @param {*} setObj 
     * @param {*} Obj 
     */
    const handleChageDayFlg = (e, setObj, Obj) =>{

        var name = e.target.name;
        var time = document.getElementById(name).value;
        var day = e.target.value;

        setObj({
            ...Obj,
            [name]: new Date(day + " " + time)
        })
        
    }


    /**
     * 【リスト】当翌フラグを切り替える
     * @param {*} e 
     * @param {*} setObjList 
     * @param {*} ObjList 
     * @param {*} index 
     */
    const handleChageDayFlgList = (e, setObjList, ObjList, index) =>{

        var Obj = ObjList[index]

        const name = e.target.name;
        const time = document.getElementById(name).value;
        const day = e.target.value;

        Obj[name] = new Date(day + " " + time);
        ObjList[index]=Obj;
        const list = ObjList.map((p)=>{ return p});
        
        setObjList(list);
        
    }

    /**
     * 【単体】申請時間を変更する
     * @param {*} e 
     * @param {*} setObj 
     * @param {*} Obj 
     */
    const handleChageTime = (e, setObj, Obj) =>{

        var name = e.target.name;
        var time = e.target.value;
        var day = Obj[name] ? commonFnc.formatDate(Obj[name]) : commonFnc.formatDate(Kintai_bsc.date);

        setObj({
            ...Obj,
            [name]: new Date(day + " " + time)
        })

    }

    /**
     * 【リスト】申請時間を変更する
     * @param {*} e 
     * @param {*} setObjList 
     * @param {*} ObjList
     * @param {*} index 
     */
    const handleChageTimeList = (e, setObjList, ObjList, index) =>{

        var Obj = ObjList[index]

        const name = e.target.name;
        const time = e.target.value;
        const day = Obj[name] ? commonFnc.formatDate(Obj[name]) : commonFnc.formatDate(Kintai_bsc.date);

        Obj[name] = new Date(day + " " + time);
        ObjList[index]=Obj;
        const list = ObjList.map((p)=>{ return p});

        setObjList(list);
        
    }

    /**
     * ListにItemを追加する。
     * @param {*} setObjList 
     * @param {*} ObjList 
     * @param {*} Obj 
     */
    const addListItem = (setObjList, ObjList, Obj) =>{
        ObjList.push(Obj);
        const list = ObjList.map((p)=>{ return p});
        setObjList(list);
        
    }

    const deleteListItem = (setObjList, ObjList, index)=>{
        ObjList.splice(index,1);
        const list = ObjList.map((p)=>{ return p});
        setObjList(list);        
    }

    /**
     * 編集中の残業申請か判定する。
     * @param {*} Request_overtime 残業申請レコード
     * @returns {bool}
     */
    const isUnderEditOvertime = (Request_overtime) =>{
        const approval_status_cd = Request_overtime.approval_status_cd;
        const over_time_from = Request_overtime.over_time_from;
        const over_time_to = Request_overtime.over_time_to;
        const reason = Request_overtime.reason;

        // 入力がある未反映の申請が存在すればtrue
        return (approval_status_cd==="10" && (over_time_from || over_time_to || reason))
    }


    /**
     * submit前のバリデーション後、登録処理
     * @param {*} e 
     */
    const cancelSubmit = async (e)=>{

        let msg = ""

        msg += regularValidate(msg);

        setErrmsg(msg);
        if(msg.length > 0){
            alert(msg);
            // e.preventDefault();
            return;
        }

        if(Kintai_bsc.user_id !== Login_user_info.user_id){
            const result = window.confirm(`${display_user_info.first_name}さんの勤怠です。\n修正しますか？`);
            if(!result) return;
        }
        
        if(display_user_info.enable_public_holiday_days < Kintai_bsc.public_holiday_time/8){
            const result = window.confirm("公休残数が足りません。それでも登録しますか？");
            if(!result) return;
        }
        if(display_user_info.enable_paid_holiday_days < Kintai_bsc.paid_holiday_time/8){
            const result = window.confirm("有給残数が足りません。それでも登録しますか？");
            if(!result) return;
        }

        let shift_type_cd_change_flg= false;

        // シフト変更チェック機能の有効化
        if(IS_ENABLE_SHIFT_CHANGE_CHECK){

            // 登録済みのシフトから修正しているか。
            // 一度修正したものは修正フラグを変更しない。
            if(!Kintai_bsc.shift_type_cd_change_flg){
                if(before_submit_shift_type_cd === Kintai_bsc.shift_type_cd){
                    shift_type_cd_change_flg = false;
                }else{
                    // 未登録からの変更はfalse
                    shift_type_cd_change_flg = !before_submit_shift_type_cd ? false : true;
                }
            }else{
                shift_type_cd_change_flg = true;
            }
    
            // 今回シフトを変更している　かつ　初めての修正
            if(shift_type_cd_change_flg && !Kintai_bsc.shift_type_cd_change_flg){
                const result = window.confirm("シフトを変更しますか？");
                if(!result) return;
            }

        }

        // 勤怠情報登録 
        var url = "/api/insert/kintai_bsc"
        var bodyData = {
            user_id:Kintai_bsc.user_id,
            date:commonFnc.formatDate(Kintai_bsc.date),
            shift_type_cd:Kintai_bsc.shift_type_cd,
            harf_holiday_shift_type_cd:Kintai_bsc.harf_holiday_shift_type_cd,
            paid_holiday_shift_type_cd:Kintai_bsc.paid_holiday_shift_type_cd,
            work_time_from:Kintai_bsc.work_time_from,
            work_time_to:Kintai_bsc.work_time_to,
            approval_status_cd:Kintai_bsc.approval_status_cd,
            work_time_msec:Kintai_bsc.work_time,
            midnight_work_time_msec:Kintai_bsc.midnight_work_time,
            rest_time_msec:Kintai_bsc.rest_time,
            over_time_msec:Kintai_bsc.over_time,
            midnight_over_time_msec:Kintai_bsc.midnight_over_time,
            behind_time_msec:Kintai_bsc.behind_time,
            early_time_msec:Kintai_bsc.early_time,
            out_time_msec:Kintai_bsc.out_time,
            first_approval_userid:Login_user_info?.first_approval_userid ? Login_user_info?.first_approval_userid : department_mst?.first_approval_userid,
            second_approval_userid:Login_user_info?.second_approval_userid ? Login_user_info?.second_approval_userid : department_mst?.second_approval_userid,
            next_approval_userid:"",
            training_time_msec:Kintai_bsc.training_time,
            kintai_note:Kintai_bsc.kintai_note,
            approval_note:Kintai_bsc.approval_note,
            public_holiday_time:Kintai_bsc.public_holiday_time,
            paid_holiday_time:Kintai_bsc.paid_holiday_time,
            count1value:Kintai_bsc.count1,
            count2value:Kintai_bsc.count2,
            count3value:Kintai_bsc.count3,
            count4value:Kintai_bsc.count4,
            count5value:Kintai_bsc.count5,
            // out_time_from:Kintai_outtime.out_time_from,
            // out_time_to:Kintai_outtime.out_time_to,
            request_paid_holiday_reason:Request_paid_holiday.request_paid_holiday_reason,
            enable_public_holiday_days:is_change_shift?(display_user_info?.enable_public_holiday_days - Kintai_bsc.public_holiday_time/8 + before_enable_public_holiday_time/8):display_user_info?.enable_public_holiday_days,
            enable_paid_holiday_days:is_change_shift?(display_user_info?.enable_paid_holiday_days - Kintai_bsc.paid_holiday_time/8 + before_enable_paid_holiday_time/8):display_user_info?.enable_paid_holiday_days,
            request_overtime_list:JSON.stringify(Request_overtimes),
            last_update_user_id:Login_user_info.user_id,
            shift_type_cd_change_flg:shift_type_cd_change_flg,
        };

        const requestOptions = {
            method: 'POST',
            headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
            body: JSON.stringify(bodyData)
        };

        
        try {
            const res = await fetch(url, requestOptions);
            if (res.ok) {

                // ログ出力
                commonFnc.insertUserLog("INFO", Login_user_info.user_id, __filename, "勤怠申請画面", "勤怠登録処理", "登録処理", "", null, bodyData);
                
                const queryGetLoginUser = new URLSearchParams({user_id: login_user_id});
                const resGetLoginUser = await fetch(`/api/select/login_user?${queryGetLoginUser}`);
                const dataGetLoginUser = await resGetLoginUser.json();
                setLogin_user_info(dataGetLoginUser);

                // リクエストが成功したらリダイレクトする
                // setTriggerReloadData(!triggerReloadData)
                if(isMobile){
                    history.push('/sp_kintaiListPage');
                }else{
                    history.push('/Kintai');
                }
            } else {
                // リクエストが失敗した場合の処理
                alert("登録に失敗しました。カレンダーから再度お試しください。");
                history.goBack();
            }
        } catch (error) {
            console.error('Error:', error);
        }

    }

    const regularValidate = (msg)=>{

        const shift = Shift_msts.find((p)=>p.shift_type_cd === Kintai_bsc.shift_type_cd);
        if(
            (
             shift?.shift_group_cd !== shift_group_cd_hash["HARF_WORK_SHIFT_GROUP_CD"] &&
             shift?.shift_group_cd !== shift_group_cd_hash["HARF_OVER_NIGHT_WATCH_SHIFT_GROUP_CD"]
            ) 
            && Kintai_bsc.harf_holiday_shift_type_cd){
            msg += "半日勤務が選択されていません。半日休暇を削除してください。\r\n"
        }else if(
            (
                shift?.shift_group_cd === shift_group_cd_hash["HARF_WORK_SHIFT_GROUP_CD"] || 
                shift?.shift_group_cd === shift_group_cd_hash["HARF_OVER_NIGHT_WATCH_SHIFT_GROUP_CD"]
            ) 
            && !Kintai_bsc.harf_holiday_shift_type_cd){
            msg += "半日休暇を選択してください。\r\n"

        }
        const flg = BL_requestKintai.isNotEqualTotalTimeAndRequestTime(Kintai_bsc, Shift_msts, UNIT_WORK_TIME_TYPE);
        if(flg){
            // setIsErrNotEqualTotalTimeAndRequestTime(flg);
            msg += "申請時間数と勤務時間数に差があります。\r\n"
        }
        if(!IS_ENABLE_NEGATIVE_PUBLIC_HOLIDAY_REQUEST){
            const enable_paid_holiday_days = display_user_info?.enable_public_holiday_days - Kintai_bsc.public_holiday_time/8
            if(enable_paid_holiday_days < 0){
                msg += "公休残数が足りません。\r\n"
            }

        }
        if(!IS_ENABLE_NEGATIVE_PAID_HOLIDAY_REQUEST){
            const enable_paid_holiday_days = display_user_info?.enable_paid_holiday_days - Kintai_bsc.paid_holiday_time/8
            if(enable_paid_holiday_days < 0){
                msg += "有給残数が足りません。\r\n"
            }

        }
        if(Kintai_bsc.paid_holiday_time > 0 && !Request_paid_holiday.request_paid_holiday_reason){
            msg += "有給申請理由を入力してください。\r\n"
        }

        for(var i=0; i<Request_overtimes.length; i++){
            if(isUnderEditOvertime(Request_overtimes[i])){
                msg += "未反映の残業申請があります。\r\n";
                break;
            }    
        }

        return msg;

    }

    /**
     * 表示中勤怠情報の日付を変更する。
     * @param {*} num 移動する日数
     */
    const changeDate = async (num) =>{
        

        const targetUseId = Kintai_bsc.user_id;
        const targetDate = new Date(Kintai_bsc.date);
        targetDate.setDate(targetDate.getDate() + num);
        const targetDateStr = commonFnc.formatDate(targetDate);
        

        // 登録済みの勤怠情報を取得
        const query = new URLSearchParams({ date: targetDateStr, user_id: targetUseId });
        const res = await fetch(`/api/select/one_kintai_bsc?${query}`);
        const kintaiData = await res.json();

        const kintaiIemplate = new Kintai_bsc_class.Kintai_bsc({date:targetDate, user_id:targetUseId});
        if(kintaiData){
            setKintai_bsc({...kintaiIemplate , ...kintaiData});
        }else{
            setKintai_bsc(new Kintai_bsc_class.Kintai_bsc({date:targetDate, user_id:targetUseId}));
        }

        // 残業情報取得
        const queryRequest_over_time = new URLSearchParams({login_user_id: targetUseId, date: targetDateStr});
        const resOverTime = await fetch(`/api/select/request_overtime?${queryRequest_over_time}`);
        const dataOverTime = await resOverTime.json();
        if(!dataOverTime || dataOverTime.length === 0){
            setRequest_overtimes([new Kintai_overtime(Kintai_bsc)])
        }else{
            setRequest_overtimes(dataOverTime)
        }

        // 有給情報取得
        const queryRequest_paid_holiday = new URLSearchParams({login_user_id: targetUseId, date: targetDateStr});
        const resPaidHoliday = await fetch(`/api/select/request_paid_holiday?${queryRequest_paid_holiday}`);
        const dataPaidHoliday = await resPaidHoliday.json();
        if(!dataPaidHoliday){
            setRequest_paid_holiday({
                request_paid_holiday_reason: "",
            })
        }else{
            setRequest_paid_holiday(dataPaidHoliday)
        }

    }



    /**
     * 画面表示用の有給公休残数の文字列を取得する。
     * @param {*} nowDays 
     * @returns 
     */
    const calcViewEnableHolidays = (nowDays) =>{

        const daysStr = Math.trunc(nowDays) + "日";

        const time = (nowDays - Math.trunc(nowDays))*8;
        if(time===0){
            return daysStr;
        }else{
            const timesStr = time + "h";
            return daysStr + "/" + timesStr;
        }
    }

    const spStyles={
        formRow:{
            display :'flex',
            margin:'4px 0px',
            flexWrap: 'wrap',
            width:'100%',
        },
        textArea:{
            display:'table',
        },
        formText:{
            color:'gray',
            fontSize:'0.7rem',
            fontWeight:'bold',
            padding: '0px 5px 0px 0px !important',
            verticalAlign: 'middle',
            textAlign:'center',
            display:'table-cell'
        },
        formButton:{
            width:'100%',
            margin:"0px 7px",
            fontSize:'0.5rem',
        },


        textField:{
            fontWeight:"bold",
            fontSize:'0.7rem',
        },
        textFieldTime:{
            fontWeight:"bold",
            fontSize:'0.7rem',
            width:'90px',
            // padding:'0px'
        },
        textFieldNote:{
            fontSize:'0.7rem',
            width:'100%',
        },
        button:{ 
            fontSize: '0.7rem', 
            margin:'2px',
            height:'50px',
            width:'20%',
        },
        shiftAcctionButtonArea:{
            margin:'0 auto',
        },
        buttonActionBase:{
            fontSize: '0.7rem', 
            height:'30px',
            margin: '2px',
            color: 'white',
        },
        buttonShiftAcction:{
            minWidth: '90px',
            backgroundColor: '#8dbb5a',
        },
        buttonShiftAcctionDisabled:{
            minWidth: '90px',
            backgroundColor: '#c0c0c0',
        },
        buttonAcction:{
            width: '100px',
            height:'50px',
            margin: '2px auto',
            backgroundColor: '#8dbb5a',
            color: 'white',
        },
        buttonAcctionDisabled:{
            width: '100px',
            height:'50px',
            margin: '2px auto',
            backgroundColor: '#c0c0c0',
            color: 'white',
        },
        selectFormControl:{
            width:'49%',
            display:'block',
            margin:'0px',
            padding:'0px',
        },
        select:{
            fontWeight:"bold",
            fontSize:'0.7rem',
            width:'33%',
            margin:'3px 0px',
            padding:'0px'
        },
        formGroup:{
            width:'100%',
            fontSize:'0.7rem',
            border:'1px solid lightgray',
            borderRadius:'5px',
            padding:'0px 5px',
            margin:'0px 2px'
        },
        kintaiTimeView:{
            fontWeight:"bold",
            fontSize:'0.6rem',
            width:'20% !important',
            '& .MuiOutlinedInput-root': {
            padding: '5px',         // 入力フィールド部分の内側余白
            },
        },
        kintaiTimeViewItem:{
            display:'flex',
            width:'50%',
            padding:'5px',
            fontSize:'0.7rem',
        },
        kintaiTimeLabel:{
            width:'50%',
            marginRight:'10px'
        },
        overTimeItem:{
            width:'100%',
            margin:'5px 0px',
            padding:'20px 0px',
            borderBottom: '1px solid lightGray',
        },
        overTimeSelectFormControl:{
            width:'50%',
            display:'block',
            margin:'0px',
            padding:'0px',
        },
        overTimeSelect:{
            fontWeight:"bold",
            fontSize:'0.7rem',
            width:'33%',
            margin:'3px 0px',
            height:'33px',
            '& .MuiSelect-select':{
                padding:'5px'
            }
        },
        overTimeTextFieldBase:{
            fontSize:'0.7rem',
            margin:'3px 0px',
            '& .MuiOutlinedInput-input':{
                padding:'5px'
            }
        },
        overTimeFieldNote:{
            fontSize:'0.6rem',
            padding:'0px',
            '& .MuiOutlinedInput-root':{
                fontSize:'0.7rem',
                padding:'10px 5px',
                width:'100%',
            }
        },
        buttonOverTime:{
            fontSize:'0.7rem',
            width:'48%',
            margin:'0px auto' ,
        },
    }

    return(
        // <form action="/api/insert/kintai_bsc" method="POST" className={classes.root} onSubmit={e=>cancelSubmit(e)}>
        <form className={classes.root} onSubmit={e=>cancelSubmit(e)}>
        {!Kintai_bsc ? <div>Loding...</div> : 
            !isMobile?
            // ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■ //
            // ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■ //
            // 
            //                                 PC画面
            // 
            // ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■ //
            // ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■ //
            <div className="request-form" noValidate>
                <Accordion defaultExpanded={true}>
                    <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    >
                    <Typography className={classes.heading} style={{width:'70%'}}>勤務申請</Typography>
                    <Typography className={classes.heading} style={{width:'30%', textAlign:'right'}}>
                        {`最終更新者：${Kintai_bsc.last_update_user_id?Kintai_bsc.last_update_user_id:"    "}`}　　
                        {`最終更新日時：${Kintai_bsc.last_update_date?Kintai_bsc.last_update_date:"----/--/--"}　${Kintai_bsc.last_update_time?Kintai_bsc.last_update_time:"--:--"}`}
                    </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Box  sx={{display:'block'}} >
                            {!errmsg?<></>:
                                <div class="errmsg">
                                    {errmsg}
                                </div>
                            }
                            <Box component="div" sx={styles.formRow}>
                                <Box sx={styles.textArea}>
                                    <Box component="div" sx={styles.formText}>
                                        <Button onClick={()=>changeDate(-1)}>＜</Button>
                                        {`日付：${commonFnc.formatDate(Kintai_bsc.date)} ${commonFnc.getDayOfWeek(Kintai_bsc.date)}`}
                                        <Button onClick={()=>changeDate(1)}>＞</Button>
                                    </Box>
                                    
                                    <Box component="div" sx={styles.formText}>スタッフ：{display_user_info.user_id}</Box>
                                    <Box component="div" sx={styles.formText}>{display_user_info.first_name + " " + display_user_info.second_name}</Box>
                                    
                                </Box>  
                                {/* <TextField label="日付" variant="filled" name="date" type="date" value={commonFnc.formatDate(Kintai_bsc.date)}/>
                                <TextField label="職員番号" variant="filled" name="user_id" value={display_user_info.user_id}/>
                                <TextField label="氏名" variant="filled" name="user_name" value={display_user_info.first_name + " " + display_user_info.second_name}/> */}
                                {/* <Button className="acction_button" variant="outlined" onClick={() => handleTimeInputButtonClick("work_time_from")}>入打刻</Button>
                                <Button className="acction_button" variant="outlined" onClick={() => handleTimeInputButtonClick("work_time_to")}>出打刻</Button>
                                <Button className="acction_button" variant="outlined" onClick={() => handleTimeInputButtonClick(document.getElementById("shift_type_cd"))}>シフト時刻反映</Button> */}
                            </Box>
                            <Box component="div" sx={styles.formRow}>
                                {is_below_enable_public_holiday_days?
                                <TextField error label="シフト" select variant="outlined" name="shift_type_cd" id="shift_type_cd" onChange={e => handleSelectShiftType(e,before_shift_type_cd, setBefore_shift_type_cd)} value={Kintai_bsc.shift_type_cd} helperText="公休残数が足りません。">
                                    {Shift_msts
                                        .sort((a, b) => a.seq < b.seq ? -1 : 1)
                                        .filter(item => !item.department_cd || item.department_cd===display_user_info.organization_cd || item.department_cd===display_user_info.department_cd)
                                        .filter(item => !(item.shift_group_cd === shift_group_cd_hash["HARF_HOLIDAY_SHIFT_GROUP_CD"] || item.shift_group_cd === shift_group_cd_hash["TIME_PAID_HOLIDAY_SHIFT_GROUP_CD"]) && item.log_del_flg===false)
                                        .map((shist_mst, index)=>(
                                        <MenuItem key={index} value={shist_mst.shift_type_cd}>{shist_mst.shift_name}</MenuItem>
                                    ))}
                                </TextField>:
                                <TextField label="シフト" select variant="outlined" name="shift_type_cd" onChange={e => handleSelectShiftType(e,before_shift_type_cd, setBefore_shift_type_cd)} value={Kintai_bsc.shift_type_cd}>
                                    {Shift_msts
                                        .sort((a, b) => a.seq < b.seq ? -1 : 1)
                                        .filter(item => !item.department_cd || item.department_cd===display_user_info.organization_cd || item.department_cd===display_user_info.department_cd)
                                        .filter(item => !(item.shift_group_cd === shift_group_cd_hash["HARF_HOLIDAY_SHIFT_GROUP_CD"] || item.shift_group_cd === shift_group_cd_hash["TIME_PAID_HOLIDAY_SHIFT_GROUP_CD"]) && item.log_del_flg===false)
                                        .map((shist_mst, index)=>(
                                        <MenuItem key={index} value={shist_mst.shift_type_cd}>{shist_mst.shift_name +  "　(" + (shist_mst.shift_time_from?shist_mst.shift_time_from:"") + " - " + (shist_mst.shift_time_to?shist_mst.shift_time_to:"") + ")"}</MenuItem>
                                    ))}
                                </TextField>
                                }
                                <div hidden={!isHidden_addShift}>
                                    <Button
                                        className="add_shift_button add_shift_button_enable"
                                        variant="outlined"
                                        onClick={()=>setIsHidden_addShift(false)}
                                    >半日<br/>休暇</Button>
                                </div>
                                <div hidden={isHidden_addShift}>
                                    <TextField label="半日休暇シフト" select variant="outlined" name="harf_holiday_shift_type_cd"
                                        onChange={e => handleSelectShiftType(e,before_harf_holiday_shift_type_cd, setBefore_harf_holiday_shift_type_cd)}
                                        value={Kintai_bsc.harf_holiday_shift_type_cd}
                                        >
                                        {Shift_msts.sort((a, b) => a.seq < b.seq ? -1 : 1)
                                        .filter(item => !item.department_cd || item.department_cd===display_user_info.organization_cd || item.department_cd===display_user_info.department_cd)
                                        .filter(item => (item.shift_group_cd === shift_group_cd_hash["HARF_HOLIDAY_SHIFT_GROUP_CD"] 
                                                    || item.shift_group_cd === shift_group_cd_hash["HARF_SICK_LEAVE_GROUP_CD"]) 
                                                    && item.log_del_flg===false)
                                        .map((shist_mst, index)=>(
                                            <MenuItem key={index} value={shist_mst.shift_type_cd}>{shist_mst.shift_name}</MenuItem>
                                        ))}
                                    </TextField>
                                    <Button 
                                        className="add_shift_button add_shift_button_disable" 
                                        variant="outlined"
                                        onClick={()=>handleDelshiftButtonClick(Kintai_bsc.harf_holiday_shift_type_cd,"harf_holiday_shift_type_cd", setIsHidden_addShift, setBefore_harf_holiday_shift_type_cd)}
                                    >＜</Button>
                                </div>
                                <div hidden={!enable_time_paid_holiday}>

                                    <div hidden={!isHidden_addPaidShift}>
                                        <Button
                                            className="add_shift_button add_shift_button_enable"
                                            variant="outlined"
                                            onClick={()=>setIsHidden_addPaidShift(false)}
                                        >時間<br/>有給</Button>
                                    </div>
                                    <div hidden={isHidden_addPaidShift}>
                                        <TextField label="時間有給シフト" select variant="outlined" name="paid_holiday_shift_type_cd" 
                                            onChange={e => handleSelectShiftType(e,before_paid_holiday_shift_type_cd, setBefore_paid_holiday_shift_type_cd)}
                                            value={Kintai_bsc.paid_holiday_shift_type_cd}
                                            >
                                            {Shift_msts
                                                .sort((a, b) => a.seq < b.seq ? -1 : 1)
                                                .filter(item => item.shift_group_cd === shift_group_cd_hash["TIME_PAID_HOLIDAY_SHIFT_GROUP_CD"] 
                                                            && item.log_del_flg===false)
                                                .map((shist_mst, index)=>(
                                                    <MenuItem key={index} value={shist_mst.shift_type_cd}>{shist_mst.shift_name}</MenuItem>
                                            ))}
                                        </TextField>
                                        <Button 
                                            className="add_shift_button add_shift_button_disable" 
                                            variant="outlined"
                                            onClick={()=>handleDelshiftButtonClick(Kintai_bsc.paid_holiday_shift_type_cd,"paid_holiday_shift_type_cd", setIsHidden_addPaidShift, setBefore_paid_holiday_shift_type_cd)}
                                        >＜</Button>
                                    </div>
                                </div>
                                <FormControl className="shiftmstFormControl">
                                    <Select
                                    name="work_time_from"
                                    variant="outlined"
                                    onChange={e => handleChageDayFlg(e, setKintai_bsc, Kintai_bsc)}
                                    defaultValue={commonFnc.formatDate(Kintai_bsc.date)}
                                    value={Kintai_bsc.work_time_from ? commonFnc.formatDate(Kintai_bsc.work_time_from) : commonFnc.formatDate(Kintai_bsc.date)}
                                    >
                                        <MenuItem value={commonFnc.formatDate(Kintai_bsc.date)}>当</MenuItem>
                                        <MenuItem value={commonFnc.formatDate(new Date(Kintai_bsc.date).setDate(new Date(Kintai_bsc.date).getDate()+1))}>翌</MenuItem>
                                    </Select>
                                </FormControl>
                                <TextField label="開始時間" className="RequestKintai_time" InputLabelProps={{ shrink: true }} variant="outlined" id="work_time_from" name="work_time_from" type="time" onChange={e => handleChangeWorkTime(e)} value={commonFnc.ConvertJSTHHMM(Kintai_bsc.work_time_from)}
                                helperText={"打刻時間　:　" + (Clockin_time_from ? Clockin_time_from.time :"")}/>
                                
                                <FormControl className="shiftmstFormControl">
                                    <Select
                                    name="work_time_to"
                                    variant="outlined"
                                    onChange={e => handleChageDayFlg(e, setKintai_bsc, Kintai_bsc)}
                                    defaultValue={commonFnc.formatDate(Kintai_bsc.date)}
                                    value={Kintai_bsc.work_time_to ? commonFnc.formatDate(Kintai_bsc.work_time_to) : commonFnc.formatDate(Kintai_bsc.date)}
                                    >
                                        <MenuItem value={commonFnc.formatDate(Kintai_bsc.date)}>当</MenuItem>
                                        <MenuItem value={commonFnc.formatDate(new Date(Kintai_bsc.date).setDate(new Date(Kintai_bsc.date).getDate()+1))}>翌</MenuItem>
                                    </Select>
                                </FormControl>
                                <TextField label="終了時間" className="RequestKintai_time" InputLabelProps={{ shrink: true }} variant="outlined" id="work_time_to" name="work_time_to" type="time" onChange={e => handleChangeWorkTime(e)} value={commonFnc.ConvertJSTHHMM(Kintai_bsc.work_time_to)}
                                helperText={"打刻時間　:　" + (Clockin_time_to ? Clockin_time_to.time :"")}
                                inputProps={{
                                    step: 60, // 5 min
                                  }}/>  
                                  <Button  disabled={isDisable_shiftConfirm_button} className={isDisable_shiftConfirm_button?"acction_button acction_button_disabled":"acction_button"}   variant="outlined" onClick={() => handleShiftConfirmButton()}>シフト時間反映</Button>
                                  <Button  disabled={isDisable_shiftConfirm_button} className={isDisable_shiftConfirm_button?"acction_button acction_button_disabled":"acction_button"}   variant="outlined" onClick={() => handleReCalcWorkTikme()}>勤務時間再計算</Button>
                                  <div hidden={!enable_time_paid_holiday}>
                                    <Button  disabled={isDisable_shiftConfirm_button} className={isDisable_shiftConfirm_button?"acction_button acction_button_disabled":"acction_button"}   variant="outlined" onClick={() => handleAjastRestTime()}>休憩時間調整</Button>
                                  </div>
                            </Box>
                            <Box component="div" sx={styles.formRow}>
                                <TextField 
                                    label="備考" variant="outlined" name="kintai_note" 
                                    onChange={e => handleInputChage(e)} 
                                    value={Kintai_bsc.kintai_note?Kintai_bsc.kintai_note:""}
                                    multiline
                                    minRows="3"
                                    style = {{width: "100%", "padding-right":"15px"}}
                                />
                            </Box>
                            {isCounterNoUse ? <></> :
                                <Box component="div" sx={styles.formRow}>
                                    <FormGroup row className="counterContent">
                                        {count1State.isHidden?"":
                                            <FormControlLabel
                                                control={<Checkbox checked={Kintai_bsc.count1} onChange={e => handleCheckedChange(e)} name="count1"/>}
                                                label={count1State.name}
                                            />
                                        }
                                        {count2State.isHidden?"":
                                            <FormControlLabel
                                                control={<Checkbox checked={Kintai_bsc.count2} onChange={e => handleCheckedChange(e)} name="count2" />}
                                                label={count2State.name}
                                            />
                                        }
                                        {count3State.isHidden?"":
                                            <FormControlLabel
                                                control={<Checkbox checked={Kintai_bsc.count3} onChange={e => handleCheckedChange(e)} name="count3" />}
                                                label={count3State.name}
                                            />
                                        }
                                        {count4State.isHidden?"":
                                            <FormControlLabel
                                                control={<Checkbox checked={Kintai_bsc.count4} onChange={e => handleCheckedChange(e)} name="count4" />}
                                                label={count4State.name}
                                            />
                                        }
                                        {count5State.isHidden?"":
                                            <FormControlLabel
                                                control={<Checkbox checked={Kintai_bsc.count5} onChange={e => handleCheckedChange(e)} name="count5" />}
                                                label={count5State.name}
                                            />
                                        }
                                    </FormGroup>

                                </Box>
                            }
                            <Box component="div" sx={styles.formRow}>
                                
                                <TextField label="時間" className="kintai_form_info" InputLabelProps={{ shrink: true }}  variant="outlined" name="work_time" type="time" onChange={e => handleInputChageConvertHHmmToMsec(e)} value={commonFnc.getHHmmForTextField(Kintai_bsc.work_time)}/>  
                                {/* <TextField label="(内)深夜時間" className="kintai_form_info" InputLabelProps={{ shrink: true }} variant="outlined" name="midnight_work_time" type="time" onChange={e => handleInputChageConvertHHmmToMsec(e)} value={commonFnc.getHHmmForTextField(Kintai_bsc.midnight_work_time)}/>   */}
                                <TextField label="休憩" className="kintai_form_info" InputLabelProps={{ shrink: true }} variant="outlined" name="rest_time" type="time" onChange={e => handleInputChageConvertHHmmToMsec(e)} value={commonFnc.getHHmmForTextField(Kintai_bsc.rest_time)}/>
                                <TextField label="時間外" className="kintai_form_info" InputLabelProps={{ shrink: true }} variant="outlined" name="over_time" type="time" onChange={e => handleInputChageConvertHHmmToMsec(e)} value={commonFnc.getHHmmForTextField(Kintai_bsc.over_time)}/>
                                <TextField label="深夜時間外" className="kintai_form_info" InputLabelProps={{ shrink: true }} variant="outlined" name="midnight_over_time" type="time" onChange={e => handleInputChageConvertHHmmToMsec(e)} value={commonFnc.getHHmmForTextField(Kintai_bsc.midnight_over_time)}/>
                                <TextField label="遅刻" className="kintai_form_info" InputLabelProps={{ shrink: true }} variant="outlined" name="behind_time" type="time" onChange={e => handleInputChageConvertHHmmToMsec(e)} value={commonFnc.getHHmmForTextField(Kintai_bsc.behind_time)}/>
                                <TextField label="早退" className="kintai_form_info" InputLabelProps={{ shrink: true }} variant="outlined" name="early_time" type="time" onChange={e => handleInputChageConvertHHmmToMsec(e)} value={commonFnc.getHHmmForTextField(Kintai_bsc.early_time)}/>
                                <TextField label="外出" className="kintai_form_info" InputLabelProps={{ shrink: true }} variant="outlined" name="out_time" type="time" onChange={e => handleInputChageConvertHHmmToMsec(e)} value={commonFnc.getHHmmForTextField(Kintai_bsc.out_time)}/>
                                <TextField label="業務外" className="kintai_form_info" InputLabelProps={{ shrink: true }} variant="outlined" name="training_time" type="time" onChange={e => handleInputChageConvertHHmmToMsec(e)} value={commonFnc.getHHmmForTextField(Kintai_bsc.training_time)}/>
                                <TextField label="公休" className="kintai_form_info" InputLabelProps={{ shrink: true }} variant="outlined" name="rest_time" type="time" onChange={e => handleInputChageConvertHHmmToMsec(e)} value={commonFnc.getHHmmForTextField(Kintai_bsc.public_holiday_time*60*60*1000)} disabled/>
                                <TextField label="有給" className="kintai_form_info" InputLabelProps={{ shrink: true }} variant="outlined" name="rest_time" type="time" onChange={e => handleInputChageConvertHHmmToMsec(e)} value={commonFnc.getHHmmForTextField(Kintai_bsc.paid_holiday_time*60*60*1000)} disabled/>
                                
                                <input hidden label="時間" name="work_time_msec" type="number" value={Kintai_bsc.work_time} readOnly/>
                                <input hidden label="(内)深夜時間" name="midnight_work_time_msec" type="number" value={Kintai_bsc.midnight_work_time} readOnly/>
                                <input hidden label="休憩" name="rest_time_msec" type="number" value={Kintai_bsc.rest_time} readOnly/>
                                <input hidden label="時間外" name="over_time_msec" type="number" value={Kintai_bsc.over_time} readOnly/>
                                <input hidden label="深夜時間外" name="midnight_over_time_msec" type="number" value={Kintai_bsc.midnight_over_time} readOnly/>
                                <input hidden label="遅刻" name="behind_time_msec" type="number" value={Kintai_bsc.behind_time} readOnly/>
                                <input hidden label="早退" name="early_time_msec" type="number" value={Kintai_bsc.early_time} readOnly/>
                                <input hidden label="外出" name="out_time_msec" type="number" value={Kintai_bsc.out_time} readOnly/>
                                <input hidden label="業務外" name="training_time_msec" type="number" value={Kintai_bsc.training_time} readOnly/>
                                <input hidden label="汎用カウンタ1" name="count1value" type="boolean" value={Kintai_bsc.count1} readOnly/>
                                <input hidden label="汎用カウンタ2" name="count2value" type="boolean" value={Kintai_bsc.count2} readOnly/>
                                <input hidden label="汎用カウンタ3" name="count3value" type="boolean" value={Kintai_bsc.count3} readOnly/>
                                <input hidden label="汎用カウンタ4" name="count4value" type="boolean" value={Kintai_bsc.count4} readOnly/>
                                <input hidden label="汎用カウンタ5" name="count5value" type="boolean" value={Kintai_bsc.count5} readOnly/>
                            </Box>
                            </Box>
                    </AccordionDetails>
                </Accordion>
                <Accordion expanded={isExpanded_request_over_time} onChange={(event, newVal) => setIsExpanded_request_over_time(newVal)}>
                    <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    >
                    <Typography className={classes.heading}>時間外申請</Typography>
                    </AccordionSummary>
                    <AccordionDetails>                    
                        <Box component="div" sx={styles.formRow}>
                            {Request_overtimes.map((Request_overtime, index)=>(
                            <div class="flex">
                                <FormControl className="shiftmstFormControl">
                                    <Select
                                        name="over_time_from"
                                        variant="outlined"
                                        onChange={e => handleChageDayFlgList(e, setRequest_overtimes, Request_overtimes, index)}
                                        defaultValue={commonFnc.formatDate(Kintai_bsc.date)}
                                        value={Request_overtime.over_time_from ? commonFnc.formatDate(Request_overtime.over_time_from) : commonFnc.formatDate(Kintai_bsc.date)}
                                        disabled={Request_overtime.approval_status_cd==="90" ? true : false}
                                    >
                                        <MenuItem value={commonFnc.formatDate(Kintai_bsc.date)}>当</MenuItem>
                                        <MenuItem value={commonFnc.formatDate(new Date(Kintai_bsc.date).setDate(new Date(Kintai_bsc.date).getDate()+1))}>翌</MenuItem>
                                    </Select>
                                </FormControl>
                                <TextField
                                    label="開始時間"
                                    className="RequestKintai_time"
                                    InputLabelProps={{ shrink: true }} 
                                    variant="outlined" 
                                    id="over_time_from" 
                                    name="over_time_from" 
                                    type="time" 
                                    onChange={e => handleChageTimeList(e, setRequest_overtimes, Request_overtimes, index)} 
                                    value={commonFnc.ConvertJSTHHMM(Request_overtime.over_time_from)}
                                    disabled={Request_overtime.approval_status_cd === "90" ? true : false}
                                />
                                <FormControl className="shiftmstFormControl">
                                    <Select
                                    name="over_time_to"
                                    variant="outlined"
                                    onChange={e => handleChageDayFlgList(e, setRequest_overtimes, Request_overtimes, index)}
                                    defaultValue={commonFnc.formatDate(Kintai_bsc.date)}
                                    value={Request_overtime.over_time_to ? commonFnc.formatDate(Request_overtime.over_time_to) : commonFnc.formatDate(Kintai_bsc.date)}
                                    disabled={Request_overtime.approval_status_cd === "90" ? true : false}
                                    >
                                        <MenuItem value={commonFnc.formatDate(Kintai_bsc.date)}>当</MenuItem>
                                        <MenuItem value={commonFnc.formatDate(new Date(Kintai_bsc.date).setDate(new Date(Kintai_bsc.date).getDate()+1))}>翌</MenuItem>
                                    </Select>
                                </FormControl>
                                <TextField 
                                    label="終了時間" 
                                    className="RequestKintai_time" 
                                    InputLabelProps={{ shrink: true }} 
                                    variant="outlined" 
                                    id="over_time_to" 
                                    name="over_time_to" 
                                    type="time" 
                                    onChange={e => handleChageTimeList(e, setRequest_overtimes, Request_overtimes, index)} 
                                    value={commonFnc.ConvertJSTHHMM(Request_overtime.over_time_to)}
                                    disabled={Request_overtime.approval_status_cd === "90" ? true : false}
                                />
                                <TextField label="申請理由" variant="outlined" name="reason"
                                    multiline
                                    onChange={e => handleInputChageList(e, setRequest_overtimes, Request_overtimes, index)}
                                    value={Request_overtime.reason}
                                    disabled={Request_overtime.approval_status_cd === "90" ? true : false}
                                    style = {{width: "400px"}} 
                                />
                                {/* <TextField className="RequestKintai_time" InputLabelProps={{ shrink: true }} disable value={approvalTexts[Request_overtime.approval_status_cd]}/> */}
                                <input hidden label="残業開始時間" name="over_time_from_value" value={Request_overtime.over_time_from} readOnly/>
                                <input hidden label="残業終了時間" name="over_time_to_value" value={Request_overtime.over_time_to} readOnly/>
                                <input hidden label="残業理由" name="reason_value" value={Request_overtime.reason} readOnly/>
                                <input hidden label="ステータス" name="overtime_approval_status_cd" value={Request_overtime.approval_status_cd} readOnly/>
                                <Button variant="outlined" id="btn-confirm" style = {{height: "58px", margin:"8px"}} onClick={()=>handleConfirmOverTimeButtonClick(Request_overtime, index)} 
                                disabled = {Request_overtime.approval_status_cd === "90" ? true : false}
                                >{Request_overtime.approval_status_cd === "90" ? "反映済" : "反映"}</Button>
                                <Button variant="outlined" id="btn-remove" style = {{height: "58px", margin:"8px"}} 
                                    onClick={()=>handleDeleteOverTimeButtonClick(Request_overtime, index)}
                                    disabled={!(Kintai_bsc.approval_status_cd === "10" || Kintai_bsc.approval_status_cd === "80") ? true : false}
                                >
                                    削除
                                </Button>
                            </div>  
                            ))}
                            <Box sx={styles.formRow}>
                                <Button 
                                    sx={styles.formButton}
                                    fullWidth
                                    variant="outlined" 
                                    onClick={()=>addListItem(setRequest_overtimes, Request_overtimes, new Kintai_overtime(Kintai_bsc) )}
                                >＋</Button>
                            </Box>
                        </Box>
                    </AccordionDetails>
                </Accordion>
                {/* <Accordion>
                    <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    >
                    <Typography className={classes.heading}>外出申請</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div name="request-out-time[]">
                            <TextField label="外出開始" InputLabelProps={{ shrink: true }} variant="outlined" name="out_time_from" type="datetime-local" onChange={e => handleChangeWorkTime(e)} value={Kintai_outtime.out_time_from} disabled={isDisable_out_time_from}/>
                            <TextField label="外出終了" InputLabelProps={{ shrink: true }} variant="outlined" name="out_time_to" type="datetime-local" onChange={e => handleChangeWorkTime(e)} value={Kintai_outtime.out_time_to} disabled={isDisable_out_time_to}/>
                        </div>
                        <Button variant="outlined" id="btn-clone">＋</Button>
                        <Button variant="outlined" id="btn-remove">－</Button>
                    </AccordionDetails>
                </Accordion> */}
                {/* <Accordion expanded={Kintai_bsc.request_paid_holiday_reason !== undefined || isExpanded_request_paid_holiday} onChange={(event, newVal) => setIsExpanded_request_paid_holiday(newVal)}> */}
                {/* <Accordion expanded={Kintai_bsc.paid_holiday_time > 0} onChange={(event, newVal) => setIsExpanded_request_paid_holiday(newVal)}> */}
                <Accordion expanded={Kintai_bsc.paid_holiday_time > 0}>
                    <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    >
                    <Typography className={classes.heading}>有給申請理由</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                            <TextField 
                                label="申請理由" variant="outlined" name="request_paid_holiday_reason" 
                                onChange={e => handleInputChage(e)} value={Request_paid_holiday.request_paid_holiday_reason}
                                disabled={isDisable_request_paid_holiday_reason}
                                multiline
                                minRows="3"
                                style = {{width: "100%"}}
                            />
                    </AccordionDetails>
                </Accordion>
                <Accordion expanded={Kintai_bsc?.approval_note}>
                    <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    >
                    <Typography className={classes.heading}><span className={Kintai_bsc?.approval_note ? "coution" : ""}>承認者コメント</span></Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                            <TextField 
                                label="承認者コメント" variant="outlined" name="approval_note" 
                                onChange={e => handleInputChage(e)} value={Kintai_bsc.approval_note}
                                readonly
                                multiline
                                minRows="3"
                                style = {{width: "100%"}}
                            />
                    </AccordionDetails>
                </Accordion>
                <TextField label="公休消費時間" className="kintai_form_note" readonly name="public_holiday_time" value={Kintai_bsc.public_holiday_time}/>
                <TextField label="有給消費時間" className="kintai_form_note" readonly name="paid_holiday_time" value={Kintai_bsc.paid_holiday_time}/>
                {/* <TextField label="公休残数" className="kintai_form_note" readonly
                                value={Math.trunc(display_user_info?.enable_public_holiday_days) + "日"
                                + ((display_user_info?.enable_public_holiday_days%1===0)?""
                                    :"/" + (display_user_info?.enable_public_holiday_days - Math.trunc(display_user_info?.enable_public_holiday_days))*8 + "h")}
                />
                <TextField label="有給残数" className="kintai_form_note" readonly
                                value={Math.trunc(display_user_info?.enable_paid_holiday_days) + "日" 
                                + (display_user_info?.enable_paid_holiday_days%1===0?""
                                    :"/" + (display_user_info?.enable_paid_holiday_days - Math.trunc(display_user_info?.enable_paid_holiday_days))*8 + "h")}
                /> */}
                <TextField label="現在公休残数" className="kintai_form_note" readonly
                                value={calcViewEnableHolidays(display_user_info?.enable_public_holiday_days, Kintai_bsc.public_holiday_time)}
                />
                <TextField label="現在有給残数" className="kintai_form_note" readonly
                                value={calcViewEnableHolidays(display_user_info?.enable_paid_holiday_days, Kintai_bsc.paid_holiday_time)}
                />
                <input hidden label="公休残数" name="enable_public_holiday_days" type="number" value={display_user_info?.enable_public_holiday_days - Kintai_bsc.public_holiday_time/8} readOnly/>
                <input hidden label="有給残数" name="enable_paid_holiday_days" type="number" value={display_user_info?.enable_paid_holiday_days - Kintai_bsc.paid_holiday_time/8} readOnly/>
                <input hidden label="残業申請" name="request_overtime_list" type="text" value={JSON.stringify(Request_overtimes)} readOnly/>
                <TextField label="一次承認者" className="kintai_form_note" readonly name="first_approval_userid" value={display_user_info?.first_approval_userid ? display_user_info?.first_approval_userid : department_mst?.first_approval_userid}/>
                <TextField label="二次承認者" className="kintai_form_note" readonly name="second_approval_userid" value={display_user_info?.second_approval_userid ? display_user_info?.second_approval_userid : department_mst?.second_approval_userid}/>

                <TextField label="申請状況" className="kintai_form_note" readonly name="approval_status_cd" value={approvalTexts[Kintai_bsc.approval_status_cd]}/>
                
                
                {/* <Button disabled={isDisable_confirm_button} className={isDisable_confirm_button?"acction_button acction_button_disabled":"acction_button"} variant="outlined" type="submit">登録</Button> */}

                {
                    isDisable_confirm_button?
                    <>
                        <Tooltip title="申請済みの勤怠です。" placement="bottom-start">
                            <Box display="inline-block" sx={{ cursor: 'not-allowed' }}>
                                <Button disabled className={"acction_button acction_button_disabled"} variant="outlined" onClick={cancelSubmit}>登録</Button>
                            </Box>
                        </Tooltip>
                        <Tooltip title="申請済みの勤怠です。" placement="bottom-start">
                            <Box display="inline-block" sx={{ cursor: 'not-allowed' }}>
                                <Button disabled className={"acction_button acction_button_disabled"}  variant="outlined" onClick={()=>setDeletemnDlgisOpen(true)}>削除</Button>
                            </Box>
                        </Tooltip>
                    </>:
                    <>
                        <Button className={"acction_button"} variant="outlined" onClick={cancelSubmit}>登録</Button>
                        <Button className={is_change_shift?"acction_button acction_button_disabled": "acction_button"}  variant="outlined" onClick={()=>setDeletemnDlgisOpen(true)} disabled={is_change_shift}>削除</Button>
                    </>
                }<Button className="acction_button" variant="outlined" onClick={() => history.goBack()}>戻る</Button>
            </div>
            :
            // ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■ //
            // ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■ //
            // 
            //                                 SP画面
            // 
            // ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■ //
            // ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■ //
            <div className="request-form" noValidate>
                <Box sx={{textAlign:"center", fontSize:"1.2rem", padding:"0 auto", width:"100%"}}>
                    <Button style={{margin:0, padding:0}} onClick={()=>changeDate(-1)}><ArrowLeft/></Button>
                    {`${commonFnc.formatDate(Kintai_bsc.date)} ${commonFnc.getDayOfWeek(Kintai_bsc.date)}`}
                    <Button style={{margin:'0', padding:'0'}} onClick={()=>changeDate(1)}><ArrowRight/></Button>
                </Box>
                <Box sx={{textAlign:"center", fontSize:"1rem", padding:"0 auto", width:"100%"}}>
                    {display_user_info.user_id + " " + display_user_info.first_name + " " + display_user_info.second_name}
                </Box>
                <Accordion defaultExpanded={true} expanded={true}>
                    <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    >
                    <Typography className={classes.heading} style={{width:'30%'}}>勤務申請</Typography>
                    <Typography className={classes.heading} style={{width:'70%', textAlign:'right', fontSize:'10px'}}>
                        {`最終更新者：${Kintai_bsc.last_update_user_id?Kintai_bsc.last_update_user_id:"    "}`}<br/>
                        {`最終更新日時：${Kintai_bsc.last_update_date?Kintai_bsc.last_update_date:"----/--/--"} ${Kintai_bsc.last_update_time?Kintai_bsc.last_update_time:"--:--"}`}
                    </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Box  sx={{display:'block'}} >
                            {!errmsg?<></>:
                                <div class="errmsg">
                                    {errmsg}
                                </div>
                            }
                            <Box component="div" sx={spStyles.formRow}>
                                {is_below_enable_public_holiday_days?
                                <TextField 
                                    error 
                                    label="シフト" 
                                    select 
                                    variant="outlined" 
                                    name="shift_type_cd" 
                                    id="shift_type_cd" 
                                    InputProps={{style: spStyles.textField}}
                                    InputLabelProps={{style: spStyles.textField}} 
                                    onChange={e => handleSelectShiftType(e,before_shift_type_cd, setBefore_shift_type_cd)} value={Kintai_bsc.shift_type_cd} helperText="公休残数が足りません。">
                                    {Shift_msts
                                        .sort((a, b) => a.seq < b.seq ? -1 : 1)
                                        .filter(item => !item.department_cd || item.department_cd===display_user_info.organization_cd || item.department_cd===display_user_info.department_cd)
                                        .filter(item => !(item.shift_group_cd === shift_group_cd_hash["HARF_HOLIDAY_SHIFT_GROUP_CD"] 
                                            || item.shift_group_cd === shift_group_cd_hash["TIME_PAID_HOLIDAY_SHIFT_GROUP_CD"]) 
                                            && item.log_del_flg===false).map((shist_mst, index)=>(
                                        <MenuItem key={index} value={shist_mst.shift_type_cd}>
                                            {shist_mst.shift_name}
                                        </MenuItem>
                                    ))}
                                </TextField>:
                                <TextField 
                                    label="シフト" 
                                    select 
                                    variant="outlined" 
                                    name="shift_type_cd" 
                                    InputProps={{style: {...spStyles.textField,'& .MuiInputBase-root':{width:'500px'}}}}
                                    InputLabelProps={{style: spStyles.textField}} 
                                    style={{width:"50%"}}
                                    onChange={e => handleSelectShiftType(e,before_shift_type_cd, setBefore_shift_type_cd)} 
                                    value={Kintai_bsc.shift_type_cd?Kintai_bsc.shift_type_cd:""}
                                >
                                    {Shift_msts
                                        .sort((a, b) => a.seq < b.seq ? -1 : 1)
                                        .filter(item => !item.department_cd || item.department_cd===display_user_info.organization_cd || item.department_cd===display_user_info.department_cd)
                                        .filter(item => !(item.shift_group_cd === shift_group_cd_hash["HARF_HOLIDAY_SHIFT_GROUP_CD"] 
                                            || item.shift_group_cd === shift_group_cd_hash["TIME_PAID_HOLIDAY_SHIFT_GROUP_CD"]) 
                                            && item.log_del_flg===false).map((shist_mst, index)=>(
                                        <MenuItem key={index} value={shist_mst.shift_type_cd}>
                                            {shist_mst.shift_name +  "　(" + (shist_mst.shift_time_from?shist_mst.shift_time_from:"") + " - " + (shist_mst.shift_time_to?shist_mst.shift_time_to:"") + ")"}
                                        </MenuItem>
                                    ))}
                                </TextField>
                                }
                                <Button
                                    style={{...spStyles.button, backgroundColor:!isHidden_addShift?'lightgray':'transparent'}}
                                    variant="outlined"
                                    onClick={()=>isHidden_addShift ? 
                                            setIsHidden_addShift(false) :
                                            handleDelshiftButtonClick(Kintai_bsc.harf_holiday_shift_type_cd,"harf_holiday_shift_type_cd", setIsHidden_addShift, setBefore_harf_holiday_shift_type_cd)}
                                    // disabled={!isHidden_addShift}
                                >半日<br/>休暇
                                </Button>
                                <Button
                                    style={{...spStyles.button, backgroundColor:!isHidden_addPaidShift?'lightgray':'transparent'}}
                                    variant="outlined"
                                    onClick={()=>isHidden_addPaidShift ? 
                                            setIsHidden_addPaidShift(false) : 
                                            handleDelshiftButtonClick(Kintai_bsc.paid_holiday_shift_type_cd,"paid_holiday_shift_type_cd", setIsHidden_addPaidShift, setBefore_paid_holiday_shift_type_cd)}
                                    // disabled={!isHidden_addPaidShift}
                                >時間<br/>有給
                                </Button>
                            </Box>
                            <Box component="div" sx={spStyles.formRow}>
                                {isHidden_addShift?<></>:
                                <>
                                    <TextField 
                                        label="半休シフト" select variant="outlined" name="harf_holiday_shift_type_cd"
                                        InputProps={{style: spStyles.textField}}
                                        InputLabelProps={{style: spStyles.textField}} 
                                        style={{width:"72%"}}
                                        onChange={e => handleSelectShiftType(e,before_harf_holiday_shift_type_cd, setBefore_harf_holiday_shift_type_cd)}
                                        value={Kintai_bsc.harf_holiday_shift_type_cd?Kintai_bsc.harf_holiday_shift_type_cd:""}
                                        >
                                        {Shift_msts
                                        .sort((a, b) => a.seq < b.seq ? -1 : 1)
                                        .filter(item => !item.department_cd || item.department_cd===display_user_info.organization_cd || item.department_cd===display_user_info.department_cd)
                                        .filter(item => (item.shift_group_cd === shift_group_cd_hash["HARF_HOLIDAY_SHIFT_GROUP_CD"] 
                                                    || item.shift_group_cd === shift_group_cd_hash["HARF_SICK_LEAVE_GROUP_CD"]) 
                                                    && item.log_del_flg===false)
                                            .map((shist_mst, index)=>(
                                            <MenuItem key={index} value={shist_mst.shift_type_cd}>{shist_mst.shift_name}</MenuItem>
                                        ))}
                                    </TextField>
                                    <Button 
                                        style={spStyles.button}
                                        variant="outlined"
                                        onClick={()=>handleDelshiftButtonClick(Kintai_bsc.harf_holiday_shift_type_cd,"harf_holiday_shift_type_cd", setIsHidden_addShift, setBefore_harf_holiday_shift_type_cd)}
                                    >＜</Button>
                                </>
                                }
                            </Box>
                            <Box component="div" sx={spStyles.formRow}>
                                    {!enable_time_paid_holiday ? <></> : isHidden_addPaidShift ? <></> :
                                    <>
                                        <TextField 
                                            label="時間有給" 
                                            select 
                                            variant="outlined" 
                                            name="paid_holiday_shift_type_cd" 
                                            InputProps={{style: spStyles.textField}}
                                            InputLabelProps={{style: spStyles.textField}} 
                                            style={{width:"72%"}}
                                            onChange={e => handleSelectShiftType(e,before_paid_holiday_shift_type_cd, setBefore_paid_holiday_shift_type_cd)}
                                            value={Kintai_bsc.paid_holiday_shift_type_cd}
                                            >
                                            {Shift_msts.sort((a, b) => a.seq < b.seq ? -1 : 1)
                                            .filter(item => item.shift_group_cd === shift_group_cd_hash["TIME_PAID_HOLIDAY_SHIFT_GROUP_CD"] 
                                                        && item.log_del_flg===false)
                                            .map((shist_mst, index)=>(
                                                <MenuItem key={index} value={shist_mst.shift_type_cd}>{shist_mst.shift_name}</MenuItem>
                                            ))}
                                        </TextField>
                                        <Button 
                                            style={spStyles.button}
                                            variant="outlined"
                                            onClick={()=>handleDelshiftButtonClick(Kintai_bsc.paid_holiday_shift_type_cd,"paid_holiday_shift_type_cd", setIsHidden_addPaidShift, setBefore_paid_holiday_shift_type_cd)}
                                        >＜</Button>
                                    </>
                                    }
                            </Box>
                            <Box component="div" sx={spStyles.formRow}>
                                <FormControl style={spStyles.selectFormControl}>
                                    <Select
                                        name="work_time_from"
                                        variant="outlined"
                                        style={spStyles.select}
                                        onChange={e => handleChageDayFlg(e, setKintai_bsc, Kintai_bsc)}
                                        defaultValue={commonFnc.formatDate(Kintai_bsc.date)}
                                        value={Kintai_bsc.work_time_from ? commonFnc.formatDate(Kintai_bsc.work_time_from) : commonFnc.formatDate(Kintai_bsc.date)}
                                    >
                                        <MenuItem value={commonFnc.formatDate(Kintai_bsc.date)} >当</MenuItem>
                                        <MenuItem value={commonFnc.formatDate(new Date(Kintai_bsc.date).setDate(new Date(Kintai_bsc.date).getDate()+1))}>翌</MenuItem>
                                    </Select>
                                <TextField 
                                    label="開始時間" 
                                    InputProps={{style: spStyles.textFieldTime}} 
                                    InputLabelProps={{ shrink: true }} 
                                    variant="outlined" id="work_time_from" name="work_time_from" type="time" onChange={e => handleChangeWorkTime(e)} value={commonFnc.ConvertJSTHHMM(Kintai_bsc.work_time_from)}
                                    helperText={Clockin_time_from ? `(${Clockin_time_from.time})` :"--:--"}/>
                                
                                </FormControl>
                                <FormControl style={spStyles.selectFormControl}>
                                    <Select
                                        name="work_time_to"
                                        variant="outlined"
                                        style={spStyles.select}
                                        onChange={e => handleChageDayFlg(e, setKintai_bsc, Kintai_bsc)}
                                        defaultValue={commonFnc.formatDate(Kintai_bsc.date)}
                                        value={Kintai_bsc.work_time_to ? commonFnc.formatDate(Kintai_bsc.work_time_to) : commonFnc.formatDate(Kintai_bsc.date)}
                                    >
                                        <MenuItem value={commonFnc.formatDate(Kintai_bsc.date)}>当</MenuItem>
                                        <MenuItem value={commonFnc.formatDate(new Date(Kintai_bsc.date).setDate(new Date(Kintai_bsc.date).getDate()+1))}>翌</MenuItem>
                                    </Select>
                                <TextField 
                                    label="終了時間" 
                                    InputProps={{style: spStyles.textFieldTime}} 
                                    InputLabelProps={{ shrink: true}} 
                                    variant="outlined" id="work_time_to" name="work_time_to" type="time" onChange={e => handleChangeWorkTime(e)} value={commonFnc.ConvertJSTHHMM(Kintai_bsc.work_time_to)}
                                    helperText={Clockin_time_to ? `(${Clockin_time_to.time})` :"--:--"}/>
                                
                                </FormControl>
                            </Box>
                            <Box component="div" sx={spStyles.formRow}>
                                <Box sx={spStyles.shiftAcctionButtonArea}>
                                  <Button 
                                    disabled={isDisable_shiftConfirm_button} 
                                    style={isDisable_shiftConfirm_button?{...spStyles.buttonActionBase, ...spStyles.buttonShiftAcctionDisabled, }:{...spStyles.buttonActionBase,...spStyles.buttonShiftAcction}}
                                    variant="outlined" 
                                    onClick={() => handleShiftConfirmButton()}>シフト反映</Button>
                                  <Button
                                    disabled={isDisable_shiftConfirm_button} 
                                    style={isDisable_shiftConfirm_button?{...spStyles.buttonActionBase, ...spStyles.buttonShiftAcctionDisabled, }:{...spStyles.buttonActionBase,...spStyles.buttonShiftAcction}}
                                    variant="outlined" 
                                    onClick={() => handleReCalcWorkTikme()}>再計算</Button>
                                    {enable_time_paid_holiday?
                                    <Button
                                        disabled={isDisable_shiftConfirm_button} 
                                        style={isDisable_shiftConfirm_button?{...spStyles.buttonActionBase, ...spStyles.buttonShiftAcctionDisabled, }:{...spStyles.buttonActionBase,...spStyles.buttonShiftAcction}}
                                        variant="outlined" 
                                        onClick={() => handleAjastRestTime()}>休憩調整</Button>:<></>
                                    }
                                </Box>
                            </Box>
                            <Box component="div" sx={spStyles.formRow}>
                                <TextField 
                                    label="備考" variant="outlined" name="kintai_note" 
                                    InputProps={{style: spStyles.textFieldNote}} 
                                    InputLabelProps={{style: spStyles.textField}} 
                                    onChange={e => handleInputChage(e)} 
                                    value={Kintai_bsc.kintai_note}
                                    multiline
                                    minRows="3"
                                    style = {{width: "100%", fontSize:'0.7rem'}}
                                />
                            </Box>
                            {isCounterNoUse ? <></> :
                                <Box component="div" sx={spStyles.formRow}>
                                    <FormGroup 
                                        row
                                        style={spStyles.formGroup}
                                    >
                                        {count1State.isHidden?"":
                                            <FormControlLabel
                                                control={<Checkbox checked={Kintai_bsc.count1} onChange={e => handleCheckedChange(e) } name="count1"/>}
                                                label={count1State.name}
                                            />
                                        }
                                        {count2State.isHidden?"":
                                            <FormControlLabel
                                                control={<Checkbox checked={Kintai_bsc.count2} onChange={e => handleCheckedChange(e)} name="count2" />}
                                                label={count2State.name}
                                            />
                                        }
                                        {count3State.isHidden?"":
                                            <FormControlLabel
                                                control={<Checkbox checked={Kintai_bsc.count3} onChange={e => handleCheckedChange(e)} name="count3" />}
                                                label={count3State.name}
                                            />
                                        }
                                        {count4State.isHidden?"":
                                            <FormControlLabel
                                                control={<Checkbox checked={Kintai_bsc.count4} onChange={e => handleCheckedChange(e)} name="count4" />}
                                                label={count4State.name}
                                            />
                                        }
                                        {count5State.isHidden?"":
                                            <FormControlLabel
                                                control={<Checkbox checked={Kintai_bsc.count5} onChange={e => handleCheckedChange(e)} name="count5" />}
                                                label={count5State.name}
                                            />
                                        }
                                    </FormGroup>

                                </Box>
                            }
                            
                            <Divider sx={{margin: "16px 0",}}/>
                            <Box component="div" sx={spStyles.formRow}>
                                <Box sx={spStyles.kintaiTimeViewItem}>
                                    <Box sx={spStyles.kintaiTimeLabel}>時間</Box>
                                    <Box>: {commonFnc.getHHmmForTextField(Kintai_bsc.work_time)}</Box>
                                </Box>
                                <Box sx={spStyles.kintaiTimeViewItem}>
                                    <Box sx={spStyles.kintaiTimeLabel}>休憩</Box>
                                    <Box>: {commonFnc.getHHmmForTextField(Kintai_bsc.rest_time)}</Box>
                                </Box>
                                <Box sx={spStyles.kintaiTimeViewItem}>
                                    <Box sx={spStyles.kintaiTimeLabel}>時間外</Box>
                                    <Box>: {commonFnc.getHHmmForTextField(Kintai_bsc.over_time)}</Box>
                                </Box>
                                <Box sx={spStyles.kintaiTimeViewItem}>
                                    <Box sx={spStyles.kintaiTimeLabel}>深夜残業</Box>
                                    <Box>: {commonFnc.getHHmmForTextField(Kintai_bsc.midnight_over_time)}</Box>
                                </Box>
                                <Box sx={spStyles.kintaiTimeViewItem}>
                                    <Box sx={spStyles.kintaiTimeLabel}>遅刻</Box>
                                    <Box>: {commonFnc.getHHmmForTextField(Kintai_bsc.behind_time)}</Box>
                                </Box>
                                <Box sx={spStyles.kintaiTimeViewItem}>
                                    <Box sx={spStyles.kintaiTimeLabel}>早退</Box>
                                    <Box>: {commonFnc.getHHmmForTextField(Kintai_bsc.early_time)}</Box>
                                </Box>
                                <Box sx={spStyles.kintaiTimeViewItem}>
                                    <Box sx={spStyles.kintaiTimeLabel}>外出</Box>
                                    <Box>: {commonFnc.getHHmmForTextField(Kintai_bsc.out_time)}</Box>
                                </Box>
                                <Box sx={spStyles.kintaiTimeViewItem}>
                                    <Box sx={spStyles.kintaiTimeLabel}>業務外</Box>
                                    <Box>: {commonFnc.getHHmmForTextField(Kintai_bsc.training_time)}</Box>
                                </Box>
                                <Box sx={spStyles.kintaiTimeViewItem}>
                                    <Box sx={spStyles.kintaiTimeLabel}>公休</Box>
                                    <Box>: {commonFnc.getHHmmForTextField(Kintai_bsc.public_holiday_time)}</Box>
                                </Box>
                                <Box sx={spStyles.kintaiTimeViewItem}>
                                    <Box sx={spStyles.kintaiTimeLabel}>有給</Box>
                                    <Box>: {commonFnc.getHHmmForTextField(Kintai_bsc.paid_holiday_time)}</Box>
                                </Box>
                            </Box>
                        </Box>
                    </AccordionDetails>
                </Accordion>
                <Accordion expanded={isExpanded_request_over_time} onChange={(event, newVal) => setIsExpanded_request_over_time(newVal)}>
                    <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    >
                    <Typography className={classes.heading}>時間外申請</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                    <Box component="div" sx={spStyles.formRow}>
                            {Request_overtimes.map((Request_overtime, index)=>(
                            <Box sx={spStyles.overTimeItem}>
                                <Box component="div" sx={spStyles.formRow}>
                                    <FormControl sx={spStyles.overTimeSelectFormControl}>
                                        <Select
                                            name="over_time_from"
                                            variant="outlined"
                                            sx={spStyles.overTimeSelect}
                                            onChange={e => handleChageDayFlgList(e, setRequest_overtimes, Request_overtimes, index)}
                                            defaultValue={commonFnc.formatDate(Kintai_bsc.date)}
                                            value={Request_overtime.over_time_from ? commonFnc.formatDate(Request_overtime.over_time_from) : commonFnc.formatDate(Kintai_bsc.date)}
                                            disabled={Request_overtime.approval_status_cd==="90" ? true : false}
                                        >
                                            <MenuItem value={commonFnc.formatDate(Kintai_bsc.date)}>当</MenuItem>
                                            <MenuItem value={commonFnc.formatDate(new Date(Kintai_bsc.date).setDate(new Date(Kintai_bsc.date).getDate()+1))}>翌</MenuItem>
                                        </Select>
                                        <TextField
                                            label="開始時間"
                                            sx={spStyles.overTimeTextFieldBase}
                                            style = {{width: "60%"}}
                                            InputLabelProps={{ shrink: true }} 
                                            variant="outlined" 
                                            id="over_time_from" 
                                            name="over_time_from" 
                                            type="time" 
                                            onChange={e => handleChageTimeList(e, setRequest_overtimes, Request_overtimes, index)} 
                                            value={commonFnc.ConvertJSTHHMM(Request_overtime.over_time_from)}
                                            disabled={Request_overtime.approval_status_cd === "90" ? true : false}
                                        />
                                    </FormControl>
                                    <FormControl sx={spStyles.overTimeSelectFormControl}>
                                        <Select
                                        name="over_time_to"
                                        variant="outlined"
                                        sx={spStyles.overTimeSelect}
                                        InputProps={{style:{padding: '5px'}}}
                                        onChange={e => handleChageDayFlgList(e, setRequest_overtimes, Request_overtimes, index)}
                                        defaultValue={commonFnc.formatDate(Kintai_bsc.date)}
                                        value={Request_overtime.over_time_to ? commonFnc.formatDate(Request_overtime.over_time_to) : commonFnc.formatDate(Kintai_bsc.date)}
                                        disabled={Request_overtime.approval_status_cd === "90" ? true : false}
                                        >
                                            <MenuItem value={commonFnc.formatDate(Kintai_bsc.date)}>当</MenuItem>
                                            <MenuItem value={commonFnc.formatDate(new Date(Kintai_bsc.date).setDate(new Date(Kintai_bsc.date).getDate()+1))}>翌</MenuItem>
                                        </Select>
                                        <TextField 
                                            label="終了時間" 
                                            sx={spStyles.overTimeTextFieldBase}
                                            style = {{width: "60%"}}
                                            InputLabelProps={{ shrink: true }} 
                                            variant="outlined" 
                                            id="over_time_to" 
                                            name="over_time_to" 
                                            type="time" 
                                            onChange={e => handleChageTimeList(e, setRequest_overtimes, Request_overtimes, index)} 
                                            value={commonFnc.ConvertJSTHHMM(Request_overtime.over_time_to)}
                                            disabled={Request_overtime.approval_status_cd === "90" ? true : false}
                                        />
                                    </FormControl>
                                </Box>
                                <Box component="div" sx={spStyles.formRow}>
                                    <TextField 
                                        label="申請理由" variant="outlined" name="reason"
                                        sx={ spStyles.overTimeFieldNote} 
                                        style = {{width: "100%", margin:'3px 0px'}}
                                        InputLabelProps={{ shrink: true }} 
                                        onChange={e => handleInputChageList(e, setRequest_overtimes, Request_overtimes, index)}
                                        multiline
                                        value={Request_overtime.reason}
                                        disabled={Request_overtime.approval_status_cd === "90" ? true : false}
                                    />
                                </Box>
                                <Box component="div" sx={spStyles.formRow}>
                                    <Button 
                                        variant="outlined" 
                                        id="btn-confirm" 
                                        style={spStyles.buttonOverTime}
                                        onClick={()=>handleConfirmOverTimeButtonClick(Request_overtime, index)} 
                                        disabled={Request_overtime.approval_status_cd === "90" ? true : false}
                                    >{Request_overtime.approval_status_cd === "90" ? "反映済" : "反映"}</Button>
                                    
                                    <Button
                                        variant="outlined"
                                        id="btn-remove"
                                        style = {spStyles.buttonOverTime}
                                        onClick={()=>handleDeleteOverTimeButtonClick(Request_overtime, index)}
                                        disabled={!(Kintai_bsc.approval_status_cd === "10" || Kintai_bsc.approval_status_cd === "80") ? true : false}
                                    >
                                        削除
                                    </Button>
                                </Box>
                            </Box>
                            ))}
                            <Button 
                                sx={spStyles.formButton}
                                fullWidth
                                variant="outlined" 
                                onClick={()=>addListItem(setRequest_overtimes, Request_overtimes, new Kintai_overtime(Kintai_bsc) )}
                            >＋</Button>
                        </Box>
                </AccordionDetails>
                </Accordion>
                <Accordion expanded={Kintai_bsc.paid_holiday_time > 0}>
                    <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    >
                    <Typography className={classes.heading}>有給申請理由</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                            <TextField 
                                label="申請理由" variant="outlined" name="request_paid_holiday_reason" 
                                InputProps={{style: spStyles.textFieldNote}} 
                                InputLabelProps={{style: spStyles.textField}} 
                                onChange={e => handleInputChage(e)} value={Request_paid_holiday.request_paid_holiday_reason}
                                disabled={isDisable_request_paid_holiday_reason}
                                multiline
                                minRows="3"
                                style = {{width: "100%", "padding-right":"7px", fontSize:'0.7rem'}}
                            />
                    </AccordionDetails>
                </Accordion>
                <Accordion expanded={Kintai_bsc?.approval_note}>
                    <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    >
                    <Typography className={classes.heading}><span className={Kintai_bsc?.approval_note ? "coution" : ""}>承認者コメント</span></Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                            <TextField 
                                label="承認者コメント" variant="outlined" name="approval_note" 
                                InputProps={{style: spStyles.textFieldNote}} 
                                InputLabelProps={{style: spStyles.textField}} 
                                onChange={e => handleInputChage(e)} value={Kintai_bsc.approval_note}
                                readonly
                                multiline
                                minRows="3"
                                style = {{width: "100%", "padding-right":"7px", fontSize:'0.7rem'}}
                            />
                    </AccordionDetails>
                </Accordion>
                <Box component="div" sx={spStyles.formRow}>
                    <TextField 
                        label="申請状況" 
                        readonly 
                        name="approval_status_cd" 
                        value={approvalTexts[Kintai_bsc.approval_status_cd]}
                        inputProps={{ style:{textAlign:"center", fontSize:'0.8rem'}}} 
                        style={{width:"30%", margin: '0 auto'}}
                    />
                    <TextField 
                        label="公休残数" 
                        readonly
                        inputProps={{ style:{textAlign:"center", fontSize:'0.8rem'}}} 
                        style={{width:"30%", margin: '0 auto'}}
                        value={Math.trunc(display_user_info?.enable_public_holiday_days) + "日"
                        + ((display_user_info?.enable_public_holiday_days%1===0)?""
                            :"/" + (display_user_info?.enable_public_holiday_days - Math.trunc(display_user_info?.enable_public_holiday_days))*8 + "h")}
                    />
                    <TextField 
                        label="有給残数" 
                        readonly
                        inputProps={{ style:{textAlign:"center", fontSize:'0.8rem'}}} 
                        style={{width:"30%", margin: '0 auto'}}
                        value={Math.trunc(display_user_info?.enable_paid_holiday_days) + "日" 
                        + (display_user_info?.enable_paid_holiday_days%1===0?""
                            :"/" + (display_user_info?.enable_paid_holiday_days - Math.trunc(display_user_info?.enable_paid_holiday_days))*8 + "h")}
                    />
                </Box>
                <Box component="div" sx={spStyles.formRow}>

                {
                    isDisable_confirm_button?
                    <>
                        <Button disabled style={spStyles.buttonAcctionDisabled}  variant="outlined" onClick={cancelSubmit}>登録</Button>
                        <Button disabled style={spStyles.buttonAcctionDisabled}   variant="outlined" onClick={()=>setDeletemnDlgisOpen(true)}>削除</Button>
                    </>:
                    <>
                        <Button style={spStyles.buttonAcction} variant="outlined" onClick={cancelSubmit}>登録</Button>
                        {/* <Button style={spStyles.buttonAcction} variant="outlined" onClick={()=>setDeletemnDlgisOpen(true)}>削除</Button> */}
                        <Button disabled={is_change_shift} style={is_change_shift?spStyles.buttonAcctionDisabled:spStyles.buttonAcction} variant="outlined" onClick={()=>setDeletemnDlgisOpen(true)}>削除</Button>
                    </>
                }<Button style={spStyles.buttonAcction} variant="outlined" onClick={() => history.goBack()}>戻る</Button>
                </Box>
                
            </div>
            }

            <CommonDialog
                msg={"削除しますか?"}
                isOpen={deleteDlgisOpen}
                doYes={() => {handleDeleteButtonClick(Kintai_bsc)}}
                doNo={() => {setDeletemnDlgisOpen(false)}}
            />
        </form>
    );
}
export default RequestKintaiForm;