import { useContext, useEffect, useState } from "react";
import SPTemplate from "../templates/SPTemplate";
import { Box, Button, colors, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@material-ui/core";
import PriorityHighIcon from '@material-ui/icons/PriorityHigh';


import * as BL_kintai from '../BL/BL_kintai';
import * as BL_approval from '../BL/BL_approval';
import * as commonFnc from '../common/commonFnc';
import {GlobalContext} from '../globalState'


import ArrowLeftIcon from "@material-ui/icons/ArrowLeft";
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import { borderRadius, fontSize, height, minWidth, width } from "@mui/system";

import { Kintai_overtime } from "../classes/Kintai_overtime_class";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";


const styles = {
  table:{
    height:'78vh'
  },
  calHeader:{
    backgroundColor:'snow',
    display:'flex'
  },
  calHeaderMonth:{
    width:'80%',
    textAlign:'center',
    fontSize:'1.4rem'
  },
  calHeaderButton:{
    width:'10%'
  },
  calcell:{
    textAlign:'center',
    padding:'3px',
    fontSize:'0.7rem',
  },
  calButton:{
    backgroundColor:'snow',
    padding:'3px',
    minWidth:'45px',
    margin:'0px',
    fontSize:'0.7rem',
  },
  calButtonCancel:{
    backgroundColor: colors.lightGreen[400],
    color:'snow',
  },
  calButtonDisabled:{
    backgroundColor: colors.grey[400],
    color:'snow',
  },
  calShift:{
    margin:'0 auto',  
    textAlign:'center',
    whiteSpace: 'nowrap',
    overflow:'hidden', 
    width:'100%',
    borderRadius:'5px',
  },
  calHelperText:{
    color:'gray'
  }

}

const SP_KintaiListPage = (props) =>{
  const {
      Login_user_info, 
      display_user_info,
      setDisplay_user_info, 
      login_user_id, 
      setLogin_user_id,

      setShiftStartDay,

      setLogin_user_info,
      setKintai_bscs, 
      Kintai_bscs, 
      Kintai_bsc, 
      setKintai_bsc, 
      Shift_msts, 
      shiftStartDay, 
      Department_msts, 
      setRequest_overtimes,
      setRequest_paid_holiday, 
      Users
  } = props;

  const history = useHistory();
  const shift_group_cd_hash = useContext(GlobalContext).shift_group_cd;

  // シフト名称のハッシュ
  var shiftHash = {};
  Shift_msts.map((Shift_mst) => (
      shiftHash[Shift_mst.shift_type_cd] = Shift_mst.shift_name
  ));

  // シフトの色のハッシュ
  var shiftColorHash = {};
  Shift_msts.map((Shift_mst) => (
      shiftColorHash[Shift_mst.shift_type_cd] = {
          backgroundcolor: Shift_mst.backgroundcolor?Shift_mst.backgroundcolor:'lightgray',
          textcolor: Shift_mst.textcolor
      }
  ));

  // 打刻情報
  const [clockin_jarnals_atMonth_atUser, setClockin_jarnals_atMonth_atUser] = useState([]);

  // 表示用の勤怠情報：打刻情報を含む1カ月のカレンダー形式
  const [Kintai_bscs_view, setKintai_bscs_view] = useState([]);
  
  // 祝日情報
  const [holidays, setHolidays] = useState({})
  useEffect(()=>{
      fetch('https://holidays-jp.github.io/api/v1/date.json')
      .then((res) => res.json())
      .then((data)=>setHolidays(data));
  },[])

  // 打刻情報と勤怠情報を再取得
  useEffect(()=>{

    getClockinJarnals();
    getKintaiBscs();

  },[shiftStartDay, display_user_info]);

  // 勤怠情報の再取得
  const getKintaiBscs = async () =>{
    if(display_user_info){
        const query = new URLSearchParams({login_user_id: display_user_info.user_id});
        const res = await fetch(`/api/select/kintai_bsc?${query}`);
        const data = await res.json();
        setKintai_bscs(data);
    }
  }

  // 打刻情報の再取得
  const getClockinJarnals = async () =>{
    
    var tmpDay = new Date(shiftStartDay);
    let shiftEndDay = tmpDay.setMonth(tmpDay.getMonth() + 1);

    const query_clockin = new URLSearchParams({target_user_id: display_user_info?.user_id, target_date_from: commonFnc.formatDate(shiftStartDay), target_date_to:commonFnc.formatDate(shiftEndDay)});
    let res_clockin = await fetch(`/api/select/clockin_jarnals_atMonth_atUser?${query_clockin}`);
    let data_clockin = await res_clockin.json();
    setClockin_jarnals_atMonth_atUser(data_clockin);
  }

  // カレンダーの再作成
  useEffect(()=>{
    var days = commonFnc.getCalendar(new Date(shiftStartDay));
    setKintai_bscs_view(commonFnc.createCalender(days, Kintai_bscs, clockin_jarnals_atMonth_atUser, display_user_info?.user_id));
  },[clockin_jarnals_atMonth_atUser,Kintai_bscs])


  // 表示月切り替え
  const handleButtonClick = (p) =>{
    var d = new Date(shiftStartDay);
    setShiftStartDay(d.setMonth(d.getMonth() + p))
  };

  const getViewMonthStr = () =>{
    
    const date = new Date(shiftStartDay);

    try {
      const y = date.getFullYear();
      const m = date.getMonth()+1;
      return `${y}年${m}月` 
    } catch (error) {
      console.log(error);
      return "";
    }
  }

  
  const moveRequestKintaiPage = ((Kintai_bsc) =>{
    setKintai_bsc(Kintai_bsc);
    // setKintai_bsc({...Kintai_bsc,harf_holiday_shift_type_cd:null});
    localStorage.setItem('mykintai_bsc',JSON.stringify(Kintai_bsc))

    // fechで取得するように変更する
    const query = new URLSearchParams({login_user_id: Kintai_bsc.user_id, date: commonFnc.formatDate(Kintai_bsc.date)});
    fetch(`/api/select/request_overtime?${query}`)
    .then((res) => res.json())
    .then((data) => {
        if(!data || data.length === 0){
            setRequest_overtimes([new Kintai_overtime(Kintai_bsc)])
        }else{
            setRequest_overtimes(data)
        }
    });

    const queryRequest_paid_holiday = new URLSearchParams({login_user_id: Kintai_bsc.user_id, date: commonFnc.formatDate(Kintai_bsc.date)});
    fetch(`/api/select/request_paid_holiday?${queryRequest_paid_holiday}`)
    .then((res) => res.json())
    .then((data) => {
        if(!data){
            setRequest_paid_holiday({
                request_paid_holiday_reason: "",
            })
        }else{
            setRequest_paid_holiday(data)
        }
    });

    let tmp_user = Users.find(item => item.user_id === display_user_info.user_id);
    setDisplay_user_info(tmp_user);

    history.push('/RequestKintai');
})
  

  // 申請ボタンクリック
  const handlerRequestButtonClick = ((target_kintai_bsc) =>{
    const target_shift = Shift_msts.find((p)=>p.shift_type_cd === target_kintai_bsc?.shift_type_cd);
    if(target_kintai_bsc.approval_status_cd === "10" || target_kintai_bsc.approval_status_cd === "80"){
        if(!target_kintai_bsc.shift_type_cd || (target_shift?.shift_group_cd === shift_group_cd_hash["HARF_WORK_SHIFT_GROUP_CD"] && !target_kintai_bsc.harf_holiday_shift_type_cd)){
            alert("半休シフトが選択されていません。");
            return;
        }
        BL_kintai.requestApproval(target_kintai_bsc, Department_msts, setKintai_bscs);
    }
    
    if(target_kintai_bsc.approval_status_cd === "20"){
        BL_kintai.requestCancel(target_kintai_bsc, Department_msts, setKintai_bscs);
    }
  });

  return (
    <SPTemplate
      setLogin_user_id={setLogin_user_id}
      setKintai_bscs={setKintai_bscs}
      Login_user_info={Login_user_info}
      setDisplay_user_info={setDisplay_user_info}>

    <Box
      style={styles.calHeader}
    >
      <Button
        style={styles.calHeaderButton}
        onClick={()=>handleButtonClick(-1)}
      >
        <ArrowLeftIcon/>
      </Button>
      <Typography style={styles.calHeaderMonth}>
        {getViewMonthStr()} 
      </Typography>
      <Button
        style={styles.calHeaderButton}
        onClick={()=>handleButtonClick(1)}
      >
        <ArrowRightIcon/>
      </Button>
    </Box>
    <TableContainer
        style={styles.table}
    >
      <Table>
        <TableHead>
          <TableRow>
            <TableCell style={styles.calcell} >日付</TableCell>
            <TableCell style={styles.calcell} >シフト</TableCell>
            <TableCell style={styles.calcell} >出勤</TableCell>
            <TableCell style={styles.calcell} >退勤</TableCell>
            <TableCell style={styles.calcell} >勤務</TableCell>
            <TableCell style={styles.calcell} >残業</TableCell>
            <TableCell style={styles.calcell} >申請</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
        {
          Kintai_bscs_view.map((Kintai_bsc, index)=>(
            <TableRow key={index} style={{
              backgroundColor: Kintai_bsc.approval_status_cd === '80' ? 'tomato' : commonFnc.getDayofWeekColor(Kintai_bsc.date, holidays)
              }}>
              <TableCell
                style={styles.calcell} 
                scope="row" align="center">
                  <Button variant="outlined" color="primary"
                      onClick={(e)=>moveRequestKintaiPage(Kintai_bsc)}
                      value={commonFnc.ConvertJST(Kintai_bsc.date).split(' ')[0]}
                      style={styles.calButton}>
                      {Kintai_bsc.date.getDate()
                      + "(" + [ "日", "月", "火", "水", "木", "金", "土" ][Kintai_bsc?.date?.getDay()] + ")"}
                  </Button>
              </TableCell>
              <TableCell style={styles.calcell}>
                <Box sx={{display:"flex"}}>
                  <div style={{width:"100%"}}>
                    <div 
                        style={{
                          ...styles.calShift,
                          backgroundColor: Kintai_bsc.shift_type_cd ? shiftColorHash[Kintai_bsc.shift_type_cd]?.backgroundcolor:"transparent" ,
                          color: Kintai_bsc.shift_type_cd ? shiftColorHash[Kintai_bsc.shift_type_cd]?.textcolor:""}}
                        // onClick={()=>handleHolidayShiftEntryButtonClick(Kintai_bsc)}
                        >
                        {shiftHash[Kintai_bsc.shift_type_cd]?shiftHash[Kintai_bsc.shift_type_cd]:"-"}
                    </div>
                    {Kintai_bsc.harf_holiday_shift_type_cd?
                    <div 
                      style={{
                        ...styles.calShift,
                        backgroundColor: Kintai_bsc.harf_holiday_shift_type_cd ? shiftColorHash[Kintai_bsc.harf_holiday_shift_type_cd]?.backgroundcolor:"" ,
                        color: Kintai_bsc.harf_holiday_shift_type_cd ? shiftColorHash[Kintai_bsc.harf_holiday_shift_type_cd]?.textcolor:""}}>
                        {shiftHash[Kintai_bsc.harf_holiday_shift_type_cd]}
                    </div>
                    :""}
                    {Kintai_bsc.paid_holiday_shift_type_cd?
                    <div
                      className={"shift_type"} 
                      style={{
                        ...styles.calShift,
                        backgroundColor: Kintai_bsc.paid_holiday_shift_type_cd ? shiftColorHash[Kintai_bsc.paid_holiday_shift_type_cd]?.backgroundcolor:"" ,
                        color: Kintai_bsc.paid_holiday_shift_type_cd ? shiftColorHash[Kintai_bsc.paid_holiday_shift_type_cd]?.textcolor:""}}>
                        {shiftHash[Kintai_bsc.paid_holiday_shift_type_cd]}
                    </div>
                    :""}
                    </div>
                    {
                        Kintai_bsc.shift_type_cd_change_flg ? <PriorityHighIcon style={{fontSize:'0.7rem', height:"100%",color:"red", margin:"auto", padding:"0px"}}/> :<></>
                    }
                </Box>
              </TableCell>
              <TableCell style={styles.calcell}>
                <span>{commonFnc.ConvertJSTHHMM(Kintai_bsc.work_time_from)}</span><br/>
                <span style={styles.calHelperText}>{(!Kintai_bsc.clockin_time_from ? "" : Kintai_bsc.clockin_time_from)}</span>
              </TableCell>
              <TableCell style={styles.calcell}>
                <span>{commonFnc.ConvertJSTHHMM(Kintai_bsc.work_time_to)}</span><br/>
                <span style={styles.calHelperText}>{(!Kintai_bsc.clockin_time_to ? "" : Kintai_bsc.clockin_time_to)}</span>
              </TableCell>
              <TableCell style={styles.calcell}>
                {!Kintai_bsc.work_time ? "" : commonFnc.getHHmm(Kintai_bsc.work_time)}
              </TableCell>
              <TableCell style={styles.calcell}>
                {!Kintai_bsc.over_time ? "" : commonFnc.getHHmm(Kintai_bsc.over_time)}
              </TableCell>
              <TableCell style={styles.calcell}>
                <Button variant="outlined" color="primary"
                    disabled={Kintai_bsc.approval_status_cd !== "10" && Kintai_bsc.approval_status_cd !== "20" && Kintai_bsc.approval_status_cd !== "80"}
                    onClick={(e)=>handlerRequestButtonClick(Kintai_bsc)}
                    style={
                      (Kintai_bsc.approval_status_cd === "10" || Kintai_bsc.approval_status_cd === "80" )?
                         styles.calButton : 
                         Kintai_bsc.approval_status_cd === "20"? 
                          {...styles.calButton, ...styles.calButtonCancel} : 
                          {...styles.calButton, ...styles.calButtonDisabled}
                      }>
                    {(Kintai_bsc.approval_status_cd === "10" || Kintai_bsc.approval_status_cd === "80" )? "申請" : "取消"}
                </Button>
              </TableCell>
            </TableRow>
          ))
        }
        
        </TableBody>
      </Table>
    </TableContainer>
        
      
    </SPTemplate>
  );
}

export default SP_KintaiListPage;